/**
 * Defines the breakpoints
 */
export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1366,
    xl: 1920
  }
};

/**
 * Defines the typography
 */
export const typography = {
  fontFamily: "'Sora', sans-serif",
  fontDisplay: "'Sora', sans-serif"
};

/**
 * Defines the zIndex values used across the app
 */
export const zIndex = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 2100,
  drawer: 2200,
  modal: 2300,
  snackbar: 1400,
  tooltip: 1500
};
