import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import {
  GuestCarName,
  GuestCarModel,
  GuestAppointment,
  GuestAppointmentGroup,
  GuestOrganization,
} from "../../types";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";

/**
 * Imports constants
 */
import { DEFAULT_CALENDAR_ACTIVE_VIEW } from "../../constants";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  loading: boolean;
  currentDate: Date;
  calendarData: AppointmentModel[];
  organizations: GuestOrganization[];
  activeOrg?: GuestOrganization;
  calendarActiveView: string;
  activeGroupId?: number;
  appointments: GuestAppointment[];
  calendarStartTime: string;
  calendarEndTime: string;
  groupOptions: GuestAppointmentGroup[];
  carNames: GuestCarName[];
  carModels: GuestCarModel[];
  slug?: string;
  notFoundOrg?: boolean;
  excludedDays: number[];
  changeDate: (currentDate: Date, today?: boolean) => void;
  setActiveOrg: Dispatch<SetStateAction<GuestOrganization | undefined>>;
  setActiveGroupId: Dispatch<SetStateAction<number | undefined>>;
  setOrganizations: Dispatch<SetStateAction<GuestOrganization[]>>;
  setCalendarData: Dispatch<SetStateAction<AppointmentModel[]>>;
  requestOrganizationAppointments: (
    weekNumber: number,
    organizationId: string | number,
  ) => Promise<void>;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  calendarData: [],
  organizations: [],
  currentDate: new Date(),
  calendarActiveView: DEFAULT_CALENDAR_ACTIVE_VIEW,
  appointments: [],
  calendarStartTime: "08:00",
  calendarEndTime: "18:00",
  groupOptions: [],
  carNames: [],
  carModels: [],
  excludedDays: [0],
  changeDate: () => {},
  setActiveOrg: () => {},
  setActiveGroupId: () => {},
  setOrganizations: () => {},
  setCalendarData: () => {},
  requestOrganizationAppointments: async () => {},
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
