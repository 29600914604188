import { createListenerMiddleware } from "@reduxjs/toolkit";

/**
 * Imports listeners
 */
import {
  addLoginListener,
  addLogutListener,
  addAccountListener,
  addAccountDeletionListener,
  addFetchAccountInformationListener,
  addWorkOrderListener,
  addReportsListener,
  addNotificationsListener,
  addWorkOverviewListener,
} from "../features";

/**
 * Imports types
 */
import { AppStartListening } from "./store.types";

/**
 * Defines the App listener middleware
 */
export const appListenerMiddleware = createListenerMiddleware();

/**
 * Redefines the middleware listen type
 */
const startAppListening =
  appListenerMiddleware.startListening as AppStartListening;

/**
 * Starts listening to login action
 */
addLoginListener(startAppListening);
addLogutListener(startAppListening);

/**
 * Starts listening to the account actions
 */
addAccountListener(startAppListening);
addAccountDeletionListener(startAppListening);
addFetchAccountInformationListener(startAppListening);

/**
 * Starts listening to workorder actions
 */
addWorkOrderListener(startAppListening);

/**
 * Starts listening to reports actions
 */
addReportsListener(startAppListening);

/**
 * Starts listening to notifications actions
 */
addNotificationsListener(startAppListening);

/**
 * Starts listening to work-overview actions
 */
addWorkOverviewListener(startAppListening);
