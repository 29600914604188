import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports action creators
 */
import { workOverviewActionCreators } from "./workOverview.slice";

/**
 * Imports types
 */
import { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState } from "../../utils";

/**
 * Imports constants
 */
import {
  STATISTICS_KEY,
  WORKOVERVIEW_VIEW_KEY,
  WORKOVERVIEW_TYPE_KEY,
} from "../../../constants";

const { updateStatistics, updateWorkOverviewType, updateWorkOverviewView } =
  workOverviewActionCreators;
const workOverviewMatcher = isAnyOf(
  updateStatistics,
  updateWorkOverviewType,
  updateWorkOverviewView,
);

export const addWorkOverviewListener = (startListening: AppStartListening) => {
  startListening({
    matcher: workOverviewMatcher,
    effect: (action, listenerApi) => {
      if (action.type === updateWorkOverviewType.type) {
        saveState(WORKOVERVIEW_TYPE_KEY, action.payload);
      }

      if (action.type === updateWorkOverviewView.type) {
        saveState(WORKOVERVIEW_VIEW_KEY, action.payload);
      }

      if (action.type === updateStatistics.type) {
        saveState(STATISTICS_KEY, action.payload);
      }
    },
  });
};
