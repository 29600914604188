import { isToday } from "date-fns";

import { loadState } from "../../utils";
import type { Appointment } from "../../../types";
import type { AppointmentsViewType } from "./appointments.types";
import type { Resource } from "@devexpress/dx-react-scheduler";

import {
  APPOINTMENTS_VIEW_KEY,
  APPOINTMENTS_RESOURCES_KEY,
  APPOINTMENTS_ACTIVE_ORG_KEY,
  APPOINTMENTS_ACTIVE_GROUPS_KEY,
  APPOINTMENTS_WITH_GROUPING_KEY,
  APPOINTMENTS_INCLUDE_GROUPLESS_KEY,
} from "../../../constants";

export const getTodayAppointments = (appointments: Appointment[]) => {
  const result = appointments.filter((appointment) => {
    const startDate = new Date(appointment.from);

    return isToday(startDate);
  });

  return result;
};

export const preloadViewState = () => {
  const storageState = loadState(APPOINTMENTS_VIEW_KEY) as AppointmentsViewType;

  if (storageState) return storageState;
  return "today";
};

export const preloadResourcesState = () => {
  const storageState = loadState(APPOINTMENTS_RESOURCES_KEY) as Resource[];

  if (storageState) return storageState;
  return [];
};

export const preloadActiveOrganizationState = () => {
  const storageState = loadState(APPOINTMENTS_ACTIVE_ORG_KEY) as number | null;

  if (storageState) return storageState;
  return null;
};

export const preloadWithGroupingState = () => {
  const storageState = loadState(APPOINTMENTS_WITH_GROUPING_KEY) as boolean;

  if (storageState) return storageState;
  return false;
};

export const preloadActiveGroupsState = () => {
  const storageState = loadState(APPOINTMENTS_ACTIVE_GROUPS_KEY) as number[];

  if (storageState) return storageState;
  return [];
};

export const preloadIncludeGrouplessState = () => {
  const storageState = loadState(APPOINTMENTS_INCLUDE_GROUPLESS_KEY) as boolean;

  if (storageState) return storageState;
  return true;
};
