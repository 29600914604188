import { useMemo } from "react";

/**
 * Imports hooks
 */
import { useTheme } from "@mui/material/styles";
import { useWindowDimensions } from "../../hooks";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const DeviceProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the theme
   */
  const theme = useTheme();

  /**
   * Gets the width of the screen
   */
  const { width } = useWindowDimensions();

  /**
   * Checks if the screen is tablet or bellow
   */
  const isTabletOrBellow = useMemo(() => {
    return width <= theme.breakpoints.values.md;
    // eslint-disable-next-line
  }, [width]);

  /**
   * Checks if the screen is mobile
   */
  const isMobile = useMemo(() => {
    return width < theme.breakpoints.values.sm;
    // eslint-disable-next-line
  }, [width]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    isMobile,
    isTabletOrBellow,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
