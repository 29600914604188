import { useState, useEffect, useCallback } from "react";

/**
 * External imports
 */
import { cloneDeep, findIndex } from "lodash";

/**
 * Imports hooks
 */
import {
  useApi,
  useEvents,
  useActions,
  useSelector,
  useUserUtils,
  useSearchUtils,
  useLocalStorage,
  useWorkOrderUtils,
  useWorkOverviewUtils,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import {
  WorkOrder,
  FilterModel,
  GenericFilterItem,
  SocketEvent,
} from "../../types";
import {
  RequestOnError,
  UpdateWorkOrderBody,
  UpdateWorkOrderOnSuccess,
  GetDailyWorkOrdersOnSuccess,
  GetSuspendedWorkOrdersOnSuccess,
} from "..";
import { LiveWorkOrdersProvidersProps } from "./useLiveWorkOrders.types";

/**
 * Imports constants
 */
import { ITP_WORK_ORDER_TYPE_VALUE } from "../../constants";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const LiveWorkOrdersProviders: React.FC<LiveWorkOrdersProvidersProps> = (
  props,
) => {
  const { children, suspended } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Initializes the loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the live work orders
   */
  const [liveWorkOrders, setLiveWorkOrders] = useState<WorkOrder[]>([]);

  /**
   * Initializes the suspended work orders
   */
  const [suspendedWorkOrders, setSuspendedWorkOrders] = useState<WorkOrder[]>(
    [],
  );

  /**
   * Initializes the live work orders init state
   */
  const [liveWorkOrdersInit, setLiveWorkOrdersInit] = useState(false);

  /**
   * Initializes the suspended work orders init state
   */
  const [suspendedWorkOrdersInit, setSuspendedWorkOrdersInit] = useState(false);

  /**
   * Initializes the state updating flag
   */
  const [stateUpdating, setStateUpdating] = useState(false);

  /**
   * Initializes the filters
   */
  const [filters, setFilters] = useLocalStorage<GenericFilterItem[]>(
    "liveWorkOrderFilters",
    [],
  );

  /**
   * Initializes the filter models
   */
  const [filterModels, setFilterModels] = useLocalStorage<FilterModel[]>(
    "liveWorkOrderFilterModels",
    [],
  );

  /**
   * Initializes the search value
   */
  const [searchValue, setSearchValue] = useState("");

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Initializes the suspended work order view
   */
  const [viewSuspended, setViewSuspended] = useState(false);

  /**
   * Initializes the itp active flag
   */
  const [itpActive, setItpActive] = useState(false);

  /**
   * Gets the user utils
   */
  const { getDefaultWorkOrderTypeITP } = useUserUtils();

  /**
   * Gets work overview utils
   */
  const {
    updateWorkOrder,
    getDefaultStatusFilters,
    getDefaultFilterModels,
    applyEventToWorkOrders,
  } = useWorkOverviewUtils();

  /**
   * Gets the work order utils
   */
  const { getSearchPool } = useWorkOrderUtils();

  /**
   * Gets search utils
   */
  const { localSearch } = useSearchUtils();

  /**
   * Gets the filter model utility hook
   */
  const { modelsToInputs, removeFilter } = useFilterModelsUtils();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the socket event listener
   */
  const { listen } = useEvents();

  /**
   * Handles the request error
   */
  const handleError: RequestOnError = () => {
    setStateUpdating(false);
    setLoading(false);
    dispatchMessage({
      message: "Error",
      severity: "error",
    });
  };

  /**
   * Handles the search submit
   */
  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  /**
   * Handles toggling the itp active flag
   */
  const toggleItpActive = () => {
    setItpActive((prevState) => !prevState);
  };

  /**
   * Handles getting the suspended work orders
   */
  const getSuspendedWorkOrders = async () => {
    setLoading(true);

    /**
     * Handles the success of the api call
     */
    const onSuccess: GetSuspendedWorkOrdersOnSuccess = ({ data }) => {
      setLoading(false);
      setSuspendedWorkOrdersInit(true);
      setSuspendedWorkOrders(data);
    };

    /**
     * Handles getting the daily work orders
     */
    await apiCalls.getSuspendedWorkOrders(onSuccess, handleError);
  };

  /**
   * Handles getting the daily work orders
   */
  const getDailyWorkOrders = async () => {
    setLoading(true);

    /**
     * Handles the success of the api call
     */
    const onSuccess: GetDailyWorkOrdersOnSuccess = async ({ data }) => {
      setLoading(false);
      setLiveWorkOrders(data);
      setLiveWorkOrdersInit(true);
    };

    /**
     * Handles getting the daily work orders
     */
    await apiCalls.getDailyWorkOrders(onSuccess, handleError);
  };

  /**
   * Handles initializing the overview state
   */
  const initializeOverview = () => {
    if (!filters || (filters && filters.length < 1)) {
      setFilters(getDefaultStatusFilters);
    }

    if (!filterModels || (filterModels && filterModels.length < 1)) {
      setFilterModels(getDefaultFilterModels());
    }

    getDailyWorkOrders();
  };

  /**
   * Handles updating the filters
   */
  const handleFilter = (value: string) => {
    const updatedFilters = (filters || []).map((filter) => {
      return {
        ...filter,
        active: filter.value === value ? !filter.active : filter.active,
      };
    });

    setFilters(updatedFilters);
  };

  /**
   * Handles filtering the work orders
   */
  const filterWorkOrders = (workOrders: WorkOrder[], suspended?: boolean) => {
    const activeFilters = (filters || [])
      .filter((f) => f.active)
      .map((f) => f.value);

    /**
     * Initializes the result
     */
    let result = cloneDeep(workOrders).map((workOrder) => {
      const { workOrderType, workOrderTypeId } = workOrder;

      return {
        ...workOrder,
        workOrderTypeId: workOrderTypeId || workOrderType?.id,
      };
    });

    if (!suspended) {
      /**
       * Filters based on status / category
       */
      result = result.filter((workOrder) => {
        const { status } = workOrder;

        if (status === "upgrade-data") {
          return activeFilters.includes("on-hold");
        }

        return activeFilters.includes(status);
      });
    }

    /**
     * Filters based on filter models
     */
    if (filterModels && filterModels.length > 0) {
      result = result.filter((workOrder) => {
        const inputs = Object.entries(modelsToInputs(filterModels));

        return inputs.every((entry) => {
          const key = entry[0];
          const value = entry[1];

          if (key === "workOrderTypeId") {
            if (value === ITP_WORK_ORDER_TYPE_VALUE) return workOrder.itp;

            return workOrder.workOrderTypeId === value && !workOrder.itp;
          }

          if (key === "organizationId" && value === "all") return true;

          return (workOrder as any)[key] === value;
        });
      });
    }

    /**
     * Filters based on search value
     */
    const searchPool = getSearchPool(result);

    if (searchPool.length > 0) {
      result = localSearch({
        linkKey: "id",
        source: result,
        searchPool,
        searchValue,
      });
    }

    return result;
  };

  /**
   * Handles filtering the suspended work orders
   */
  const filterSuspendedWorkOrders = (workOrders: WorkOrder[]) => {
    return filterWorkOrders(workOrders, true);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    if (filterModels) {
      const updatedFilters = removeFilter(filter, filterModels);
      setFilterModels(updatedFilters);
    }
  };

  /**
   * Handles updating the work order payment type
   */
  const updatePaymentType = async (
    workOrderId: number,
    paymentTypeId: number,
    variant: "suspended" | "live",
    callback?: () => void,
  ) => {
    setStateUpdating(true);

    /**
     * Defines the req body;
     */
    const reqBody: UpdateWorkOrderBody = {
      paymentTypeId,
    };

    /**
     * Handles the success of the api call
     */
    const onSuccess: UpdateWorkOrderOnSuccess = ({ data }) => {
      if (variant === "suspended") {
        setSuspendedWorkOrders(updateWorkOrder(suspendedWorkOrders, data));
      } else {
        setLiveWorkOrders(updateWorkOrder(liveWorkOrders, data));
      }

      // setTriggerStateSync(true);
      setStateUpdating(false);
      if (callback) callback();
    };

    /**
     * Handles getting the daily work orders
     */
    await apiCalls.updateWorkOrder(
      workOrderId,
      reqBody,
      onSuccess,
      handleError,
    );
  };

  /**
   * Handles updating the work order status
   */
  const updateWorkOrderStatus = async (
    workOrderId: number,
    status: string,
    variant: "suspended" | "live",
    callback?: () => void,
  ) => {
    setStateUpdating(true);

    /**
     * Handles the success of the api call
     */
    const onSuccess: UpdateWorkOrderOnSuccess = ({ data }) => {
      if (variant === "suspended") {
        setSuspendedWorkOrders((prevState) => {
          /**
           * Gets the workorder index
           */
          const index = findIndex(prevState, { id: data.id });

          /**
           * Updates the state
           */
          if (prevState[index]) {
            prevState[index] = data;
          }
          return prevState;
        });

        // setSuspendedWorkOrders(updateWorkOrder(suspendedWorkOrders, data));
      } else {
        setLiveWorkOrders((prevState) => {
          /**
           * Gets the workorder index
           */
          const index = findIndex(prevState, { id: data.id });

          /**
           * Updates the state
           */
          if (prevState[index]) {
            prevState[index] = data;
          }

          return prevState;
        });
        // setLiveWorkOrders(updateWorkOrder(liveWorkOrders, data));
      }

      setStateUpdating(false);
      if (callback) callback();
    };

    /**
     * Handles getting the daily work orders
     */
    await apiCalls.updateWorkOrderStatus(
      workOrderId,
      status,
      onSuccess,
      handleError,
    );
  };

  // const categorizeEvents = (events: Event<any>[]) => {
  //   const liveWorkOrderEvents: Event<any>[] = [];
  //   const suspendedWorkOrderEvents: Event<any>[] = [];

  //   events.forEach((event) => {
  //     const { data } = event;

  //     const liveWOIndex = findIndex(liveWorkOrders, { id: data.model.id });

  //     const suspendedWOIndex = findIndex(suspendedWorkOrders, {
  //       id: data.model.id
  //     });

  //     if (liveWOIndex !== -1) {
  //       liveWorkOrderEvents.push(event);
  //     }

  //     if (suspendedWOIndex !== -1) {
  //       suspendedWorkOrderEvents.push(event);
  //     }

  //     if (data.event === "CREATED" && event.type === ListenType.WORK_ORDER) {
  //       liveWorkOrderEvents.push(event);
  //     }
  //   });

  //   return { liveWorkOrderEvents, suspendedWorkOrderEvents };
  // };

  /**
   * Handles updating the live work orders on socket events capture
   */
  const handleSocketEvents = (payload: SocketEvent<any>) => {
    if (suspended) {
      setSuspendedWorkOrders((prevState) =>
        applyEventToWorkOrders(prevState, payload),
      );
    } else {
      setLiveWorkOrders((prevState) =>
        applyEventToWorkOrders(prevState, payload),
      );
    }
  };

  /**
   * Initializes the state
   */
  useEffect(() => {
    if (userInitialized && !liveWorkOrdersInit && !suspended) {
      initializeOverview();
    }
    // eslint-disable-next-line
  }, [userInitialized, liveWorkOrdersInit]);

  /**
   * Triggers the api call for suspended work orders
   */
  useEffect(() => {
    if (viewSuspended && suspended) {
      getSuspendedWorkOrders();
    }
    // eslint-disable-next-line
  }, [viewSuspended, suspended]);

  useEffect(() => {
    if (userInitialized) {
      listen({
        channel: "work-order",
        event: "WorkOrder",
        withAuth: true,
        callback: (payload) => handleSocketEvents(payload),
      });
    }

    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles updating the filter models
   */
  useEffect(() => {
    if (itpActive) {
      const workOrderType = getDefaultWorkOrderTypeITP();

      if (workOrderType) {
        const filterModelsClone = cloneDeep(filterModels) || [];
        const cleanModels = filterModelsClone.filter(
          (model) => model.field !== "work_order_type_id",
        );

        cleanModels.push({
          displayOnly: false,
          field: "work_order_type_id",
          options: [],
          order: 1,
          selected: workOrderType.id,
          selectedOnly: false,
          type: "dropdown",
        });

        setFilterModels(cleanModels);
      }
    }
    // eslint-disable-next-line
  }, [itpActive]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    filters: filters || [],
    liveWorkOrdersInit,
    liveWorkOrders,
    searchValue,
    filterModels: filterModels || [],
    stateUpdating,
    suspendedWorkOrders,
    viewSuspended,
    setViewSuspended,
    setSearchValue,
    deleteFilter,
    handleSearch,
    handleFilter,
    suspendedWorkOrdersInit,
    setSuspendedWorkOrders,
    setLiveWorkOrders,
    setFilterModels,
    setStateUpdating,
    filterWorkOrders,
    updatePaymentType,
    filterSuspendedWorkOrders,
    updateWorkOrderStatus,
    setItpActive,
    itpActive,
    toggleItpActive,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
