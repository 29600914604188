import { useState } from "react";

/**
 * Imports hooks
 */
import { useTableUtils } from "../index";

/**
 * Imports types
 */
import { UseTableProps } from "./useTable.types";
import { TableData, SortOrder } from "../../types";

/**
 * Handles the state management for table components
 */
export const useTable = (props: UseTableProps) => {
  const { renderRow, totalRows, columns, rows, sorting, pagination } = props;

  /**
   * Gets the table utility functions
   */
  const {
    getDefaultSortOrder,
    getDefaultOrderBy,
    getDefaultPage,
    getDefaultRowsPerPage,
    isSortingEnabled,
    isPaginationEnabled,
    getComparator,
    stableSort,
    getPaginatedRows,
    calculateTotalPages
  } = useTableUtils();

  /**
   * Initializes the sort order
   */
  const [order, setOrder] = useState<SortOrder>(getDefaultSortOrder(sorting));

  /**
   * Initializes the order by field
   */
  const [orderBy, setOrderBy] = useState<keyof TableData>(
    getDefaultOrderBy(sorting)
  );

  /**
   * Initializes the page
   */
  const [page, setPage] = useState(getDefaultPage(pagination));

  /**
   * Initializes the rows per page
   */
  const [rowsPerPage, setRowsPerPage] = useState(
    getDefaultRowsPerPage(pagination)
  );

  /**
   * Handles sorting, updates states
   */
  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    if (isSortingEnabled(sorting)) {
      const isAsc = orderBy === property && order === "asc";

      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  /**
   * Handles rendering the table rows
   */
  const renderRows = (rows: any[]) => {
    if (!isSortingEnabled(sorting))
      return rows.map((row, index) => renderRow(row, index, rows));

    if (pagination && pagination.manual) {
      return stableSort(rows, getComparator(order, orderBy)).map((row, index) =>
        renderRow(row, index, rows)
      );
    }

    if (isPaginationEnabled(pagination)) {
      /**
       * Paginates the rows
       */
      const paginatedRows = getPaginatedRows({
        rows,
        order,
        orderBy,
        page,
        rowsPerPage
      });

      return paginatedRows.map((row, index) =>
        renderRow(row, index, paginatedRows)
      );
    }

    return stableSort(rows, getComparator(order, orderBy)).map((row, index) =>
      renderRow(row, index, rows)
    );
  };

  /**
   * Returns the total pages
   */
  const getTotalPages = () => {
    if (isPaginationEnabled(pagination)) {
      return calculateTotalPages(totalRows, rowsPerPage);
    }

    return 0;
  };

  return {
    rows,
    columns,
    order,
    orderBy,
    page,
    rowsPerPage,
    totalPages: getTotalPages(),
    sortingEnabled: isSortingEnabled(sorting),
    paginationEnabled: isPaginationEnabled(pagination),
    handleSort,
    renderRows,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy
  };
};
