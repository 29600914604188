import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  TWorkOverviewType,
  TWorkOverviewView,
  TWorkOverviewState,
} from "./workOverview.types";
import type { StatsCardItem } from "../../../types";

/**
 * Imports utils
 */
import {
  preloadStatisticsState,
  preloadWorkOverviewViewState,
  preloadWorkOverviewTypeState,
} from "./workOverview.utils";

const initialState: TWorkOverviewState = {
  view: preloadWorkOverviewViewState(),
  statistics: preloadStatisticsState(),
  workOrderType: preloadWorkOverviewTypeState(),
};

export const workOverviewSlice = createSlice({
  name: ReduxSlice.WorkOverview,
  initialState,
  reducers: {
    updateWorkOverviewView: (
      state,
      action: PayloadAction<TWorkOverviewView>,
    ) => {
      state.view = action.payload;
    },

    updateWorkOverviewType: (
      state,
      action: PayloadAction<TWorkOverviewType>,
    ) => {
      state.workOrderType = action.payload;
    },

    updateStatistics: (state, action: PayloadAction<StatsCardItem[]>) => {
      state.statistics = action.payload;
    },
  },
});

export const workOverviewActionCreators = workOverviewSlice.actions;
export const workOverviewReducer = workOverviewSlice.reducer;
