import { configureStore, combineReducers } from "@reduxjs/toolkit";

/**
 * Imports reducers
 */
import {
  authReducer,
  messageReducer,
  accountReducer,
  clientsReducer,
  snackbarReducer,
  workOrderReducer,
  reportsReducer,
  notificationsReducer,
  workOverviewReducer,
  appointmentsReducer,
} from "../features";

/**
 * Imports action-creators
 */
import {
  authActionCreators,
  accountActionCreators,
  messageActionCreators,
  clientsActionCreators,
  snackbarActionCreators,
  workOrderActionCreators,
  reportsActionCreators,
  notificationsActionCreators,
  workOverviewActionCreators,
  appointmentsActionCreators,
} from "../features";

/**
 * Imports custom thunks
 */
import {
  checkIfAuthenticated,
  updateUserAccountInformation,
} from "../features";

/**
 * Imports api slices
 */
import { apiService } from "../services";

/**
 * Imports App Listener middleware
 */
import { appListenerMiddleware } from "./listenerMiddleware";

/**
 * Handles combining the reducers
 */
export const rootReducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  account: accountReducer,
  clients: clientsReducer,
  snackbar: snackbarReducer,
  workOrder: workOrderReducer,
  reports: reportsReducer,
  notifications: notificationsReducer,
  workOverview: workOverviewReducer,
  appointments: appointmentsReducer,
  [apiService.reducerPath]: apiService.reducer,
});

/**
 * Configures the Redux store
 */
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // https://github.com/reduxjs/redux-toolkit/issues/456
      serializableCheck: false,
    })
      .concat(apiService.middleware)
      .concat(appListenerMiddleware.middleware),
});

/**
 * Defines the store action-creators
 */
export const actionCreators = {
  /**
   * App Thunks
   */
  checkIfAuthenticated,
  updateUserAccountInformation,

  /**
   * Actions
   */
  ...authActionCreators,
  ...messageActionCreators,
  ...accountActionCreators,
  ...clientsActionCreators,
  ...snackbarActionCreators,
  ...workOrderActionCreators,
  ...reportsActionCreators,
  ...notificationsActionCreators,
  ...workOverviewActionCreators,
  ...appointmentsActionCreators,
};
