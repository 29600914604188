import packageInfo from "../package.json";

/**
 * Imports types
 */
import { Paths } from "./hooks";
import { ButtonVariant } from "./components/Button";
import { Color } from "./components/Typography";
import { BadgeColor } from "./components/Badge";

export const BUILD_VERSION = `v${packageInfo.version}`;

/**
 * Defines the user role type
 */
export type UserRole = "admin" | "operator" | "worker";

/**
 * Defines the sort order type
 */
export type SortOrder = "asc" | "desc";

/**
 * Defines the align type
 */
export type Align = "left" | "center" | "right";

/**
 * Defines the report type
 */
export type ReportType =
  | "work-order"
  | "tyre-waste"
  | "sms"
  | "appointment"
  | "suspended-work-order"
  | "company-totaled"
  | "workers-totaled"
  | "work-order-products";

/**
 * Defines the history report type
 */
export type HistoryReportType =
  | "company"
  | "daily"
  | "monthly"
  | "waste"
  | "workers"
  | "advanced";

/**
 * Defines the report format
 */
export type ReportFormat = "normal" | "simple";

/**
 * Defines the data interface
 */
export interface TableData {
  [key: string]: any;
}

/**
 * Defines the search defaults interface
 */
export interface SearchDefaults {
  order?: SortOrder;
  orderBy?: string | number;
  pageCount?: number;
  pageSize?: number;
}

/**
 * Defines the InputSelectOption interface
 */
export interface InputSelectOption {
  label: string;
  value: string | number;
}

/**
 * Defines the avatar option interface
 */
export interface AvatarOption {
  color: string;
  hover: string;
}

/**
 * Defines the detailed reports config interface
 */
export interface DetailedReportsConfig {
  monthly?: boolean;
  company?: boolean;
  waste?: boolean;
  advanced?: boolean;
}

/**
 * Defines the head cell interface
 */
export interface HeadCell {
  id: string;
  label: string;
  align?: Align;
  sort?: boolean;
  sortField?: string;
  minWidth?: number;
}

/**
 * Defines the payment type names
 */
export type PaymentTypeName =
  | "CARD"
  | "FACT TERMENI"
  | "CASH"
  | "FISA TERMENI"
  | "BF"
  | "PROTOCOL"
  | "FISA"
  | "FACT";

/**
 * Defines the Payment Type interface
 */
export interface PaymentType {
  id: number;
  name: string;
  shortName: string;
  description?: string;
}

/**
 * Defines the Work Order Type interface
 */
export interface WorkOrderType {
  id: number;
  name: string;
  shortName: string;
  description?: string;
}

/**
 * Defines the Wheel Type interface
 */
export interface WheelType {
  id: number;
  name: string;
  shortName: string;
  description?: string;
}

/**
 * Defines the Car Type interface
 */
export interface CarType {
  id: number;
  name: string;
  shortName: string;
  description?: string;
}

/**
 * Defines the Account User interface
 */
export interface AccountUser {
  id: number;
  organizationId: number;
  type: UserRole;
  username: string;
  uuid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  createdAt: string;
  organization?: string; // Frontend only
  index: number; // Frontend only
  status: string; // Frontend only
  duration: number; // Frontend only
  avatarConfig?: AvatarOption;
  lastTimeSheet: UserTimesheet;
  hasPin?: boolean;
  onLists?: boolean;
}

/**
 * Defines the appointment group interface
 */
export interface AppointmentGroup {
  id: number;
  name: string;
  description?: string;
  organizationId: number;
  shortName: string;
}

/**
 * Defines the create tyre hotel interface
 */
export interface CreateTyreHotel {
  workOrderUuid: string;
  workOrderId?: string | number;
  organizationClientId?: string | number;
  clientName: string;
  carNumber?: string;
  tyreWidth?: string;
  tyreHeight?: string;
  tyreRim?: string;
  tyreName?: string;
  tyreQuantity?: string | number;
  metaData?: { [key: string]: any };
  description?: string;
  carModel?: string;
  carMake?: string;
}

/**
 * Defines the Organization interface
 */
export interface Organization {
  address: string;
  email?: string;
  id: number;
  name: string;
  phone?: string;
  regNumber: string;
  vatNumber: string;
}

/**
 * Defines the User Organization Contact interface
 */
export interface UserOrganizationContact {
  birth_date?: string;
  default: boolean;
  email: string;
  firstName: string;
  gender: "male" | "female";
  id: number;
  lastName: string;
  phoneFix: string;
  phoneMobile: string;
  fullName?: string; // Frontend use only
  index: number; // Frontend use only
}

/**
 * Defines the company data interface
 */
export interface CompanyData {
  email: string;
  id: number;
  name: string;
  phone: string;
  regNumber: string;
  vatNumber: string;
  webpage: string;
}

/**
 * Defines the User Organization interface
 */
export interface UserOrganization {
  address: {
    city: string;
    country: string;
    county: string;
    default: boolean;
    description?: string;
    fullAddress: string;
    id: number;
    name: string;
    number: string;
    postCode?: string;
    street: string;
  };
  appointmentGroups: AppointmentGroup[];
  company: {
    email?: string;
    id: number;
    name: string;
    phone?: string;
    regNumber: string;
    vatNumber: string;
    webpage?: string;
    address?: UserOrganization["address"];
  };
  contacts: UserOrganizationContact[];
  default: boolean;
  description?: string;
  documentPrefix?: string;
  documentSuffix?: string;
  regNumber: string; // Frontend only
  vatNumber: string; // Frontend only
  id: number;
  name: string;
  parentId?: number;
  timesheet?: string; // Frontend only
  timeSheet?: string;
  uuid: string;
  public: boolean;
  slug?: string;
  publicSettings: GenericObject | null;
}

/**
 * Defines the User interface
 */
export interface User {
  activeFrom: string;
  activeTo: string;
  createdAt: string;
  description?: string;
  id: number;
  name: string;
  status: string;
  uuid: string;
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  organization?: UserOrganization;
  organizations: UserOrganization[]; // User | Account types interlock
  avatarConfig?: AvatarOption;
  lastTimeSheet?: UserTimesheet;
  hasPin?: boolean;
  organizationId?: number;
}

/**
 * Defines the Account interface
 */
export interface Account {
  activeFrom: string;
  activeTo: string;
  carTypes: CarType[];
  description?: string;
  id: number;
  createdAt: string;
  name: string;
  organizations: UserOrganization[];
  paymentTypes: PaymentType[];
  status: string;
  users: AccountUser[];
  uuid: string;
  wheelTypes: WheelType[];
  workOrderTypes: WorkOrderType[];
  firstName?: string;
  lastName?: string;
  hasPin?: boolean;
  workers: Worker[];
  accountSettings: AccountSetting[];
}

/**
 * Defines the Active Package interface
 */
export interface ActivePackage {
  currency: string;
  description: string;
  duration: string;
  name: string;
  order: number;
  price: number;
  uuid: number;
}

/**
 * Defines the Module interface
 */
export interface Module {
  active: boolean;
  name: string;
  icon: string;
  order: number;
  path: string;
  uuid: number;
}

/**
 * Exports the Worker interface
 */
export interface Worker {
  id: number;
  isCompany?: boolean;
  name: string;
  organizationId: number;
}

/**
 * Exports the Client interface
 */
export interface Client {
  id: number;
  isCompany?: boolean;
  name: string;
  organizationId: number;
  address?: UserOrganization["address"];
  company?: UserOrganization["company"];
  delegateName?: string;
  description?: string;
  email?: string;
  fidelity: boolean;
  loyaltyCard?: LoyaltyCard;
  phone?: string;
  type: string;
  uuid: string;
  index?: number; // frontend only
  regNumber?: string; // frontend only
  vatNumber?: string; // frontend only
}

/**
 * Defines the filter model interface
 */
export interface FilterModel {
  field: string;
  options: string[];
  order: number;
  selected: any;
  type:
    | "like"
    | "dropdown"
    | "equals"
    | "equal"
    | "range"
    | "boolean"
    | "gte"
    | "lte";
  displayOnly?: boolean; // frontend only
  selectedOnly?: boolean; // frontend only
  disableDelete?: boolean; // frontend only
  fullSize?: boolean; // frontend only
}

/**
 * Defines the payment types enum
 */
export enum PaymentTypes {
  BF = "bf",
  CARD = "card",
  CASH = "cash",
  FACT = "fact",
  FACT_TERMENI = "fact termeni",
  FISA = "fisa",
  FISA_TERMENI = "fisa termeni",
  PROTOCOL = "protocol",
}

/**
 * Defines the car types enum
 */
export enum CarTypes {
  AUTOTURISM = "Autoturism",
  CAMION = "Camion",
  CARGO = "Cargo",
  "Suv / 4x4" = "Suv / 4x4",
}

/**
 * Defines the work order report item interface
 */
export interface WorkOrderReportItem {
  carPlateNumber: string;
  clientName: string;
  createdAt: string;
  delegateName: string;
  description: string;
  discount: number;
  duration: string;
  endDate: string;
  finished: string;
  id: number;
  isCompany: boolean;
  newTyre: boolean;
  organizationClientId: number | null;
  organizationId: number;
  paymentTypeName: string;
  productsName: string;
  servicesName: string;
  startDate: string;
  status: string;
  subtotal: number;
  tax: number;
  total: number;
  tyreDimension: string;
  updatedAt: string;
  uuid: string;
  index?: number; // frontend use only
}

/**
 * Defines the appointment report item interface
 */
export interface AppointmentReportItem {
  carPlateNumber: string;
  clientName: string;
  createdBy: string;
  from: string;
  orgName: string;
  phoneNumber: string;
  status: string;
  to: string;
  index?: number; // frontend use only
}

/**
 * Defines the sms report item interface
 */
export interface SMSReportItem {
  carPlateNumber: string;
  clientName: string;
  date: string;
  orgName: string;
  phoneNumber: string;
  status: string;
  index?: number; // frontend use only
}

/**
 * Defines the waste report item interface
 */
export interface WasteReportItem {
  carTypeId: number;
  clientName: string;
  createdAt: string;
  delegateName: string;
  description: string;
  id: number;
  isCompany: boolean;
  organizationId: number;
  plateNumber: string;
  tyreHeight: string;
  tyreWidth: string;
  tyreRim: string;
  tyreQuantity: string;
  workOrderId: number;
  workOrderUuid: string;
  index?: number; // frontend use only
  tyreDimensions: string; // frontend use only
}

/**
 * Defines the conditional type
 */
export type Conditional = "=" | "<" | ">" | ">=" | "<=";

/**
 * Defines the price condition interface
 */
export interface PriceCondition {
  condition: Conditional;
  field: string;
  id: number;
  newPrice: number;
  organizationProductId: number;
  value: string | number;
}

/**
 * Defines the product interface
 */
export interface Product {
  index?: number; // frontend only
  carTypeName?: string; // frontend only
  type?: string; // frontend only
  typeLabel?: string; // frontend only
  carTypeId?: number | null;
  description?: string | null;
  id: number;
  isCountPrice: boolean;
  isExpendable: boolean;
  isService: boolean;
  isTyre: boolean;
  name: string;
  organizationId: number;
  price: number;
  priceConditions: PriceCondition[];
  um: string;
  workOrderTypeId: number;
  organizationProductId?: number;
  discountOnNewTyre?: number;
}

/**
 * Defines the Product Form interface
 */
export interface ProductForm {
  type: string;
  quantity: string | number;
  price: string | number;
  total: string | number;
  productId: string | number;
  customProductName?: string;
  isCustomProduct?: boolean;
  isTyre?: boolean;
}

/**
 * Defines the work order report observation item interface
 */
export interface WorkOrderReportObservationItem {
  carPlateNumber: string;
  clientName: string;
  delegateName: string;
  description: string;
  id: number;
  uuid: string;
}

/**
 * Defines the payment type sum
 */
export type PaymentTypeSum = {
  [key in PaymentTypes]: {
    discount: number;
    subtotal: number;
    total: number;
  };
};

/**
 * Defines the car type sum
 */
export type CarTypeSum = {
  [key in CarTypes]: number;
};

/**
 * Defines the report metadata interface
 */
export interface ReportMetadata {
  discount: number;
  newTyreQuantity: number;
  paymentTypeSum: PaymentTypeSum;
  carTypeSum: CarTypeSum;
  subtotal: number;
  total: number;
  totalQuantity: number;
}

/**
 * Defines the report total item interface
 */
export interface ReportTotalItem {
  discount: number;
  name: string;
  paymentTypeName: PaymentTypeName;
  subtotal: number;
  total: number;
  index?: number; // frontend use only
}

export interface WorkOrderProductReportItem {
  index?: number; // frontend use only
  name: string;
  totalQuantity: number;
  price: number;
  totalPrice: number;
  um: string;
  carTypeName: string[];
  workOrderTypeName: { [key: string]: number };
  isService: boolean;
  isExpendable: boolean;
  isCountPrice: boolean;
  isTyre: boolean;
  carTypeNames: { [key: string]: number };
}

/**
 * Defines the history report item interface
 */
export interface HistoryReportItem {
  id: number;
  accountId: number;
  organizationId: number;
  createdBy: number;
  createdAt: string;
  from: string;
  to: string;
  clientName?: string;
  index?: number; // frontend use only
  queryInterval?: string; // frontend use only
  author?: string; // frontend use only
  organization?: string; // frontend use only
  options: {
    models: FilterModel[];
    orderBy: string | number;
    orderDir: SortOrder;
  };
}

/**
 * Defines the worker report item interface
 */
export interface WorkerReportItem {
  name: string;
  workOrdersCount: number;
  subtotal: number;
  discount: number;
  total: number;
  index?: number; // frontend use only
  carTypes: CarTypeSum; // frontend use only
  carTypesBuc: CarTypeSum; // frontend use only
  commonTotal: number;
  singleTotal: number;
}

/**
 * Defines the item interface
 */
export interface StatsItem {
  type: "TOTAL_MONTH" | "TOTAL_WEEKLY" | "TOTAL_YEAR";
  total?: number;
  progress: number; // 100 = Up 100%, -29 = Down 29%, 0 = Flat line 0%
  values: number[];
  timeInterval: "DAY" | "WEEK" | "MONTH" | "YEAR";
}

/**
 * Defines the stats interface
 */
export interface Statistics {
  week: StatsItem;
  month: StatsItem;
  year?: StatsItem;
}

export interface StatsCardItem {
  current: StatsItem;
  past: StatsItem;
  active: "current" | "past";
}

/**
 * Defines the work order car interface
 */
export interface WorkOrderCar {
  carKm?: string;
  description?: string;
  id: number;
  make?: string;
  manufacturingYear?: string;
  model?: string;
  plateNumber: string;
  type: string;
  vinNumber?: string;
}

/**
 * Defines the work order car type interface
 */
export interface WorkOrderCarType {
  description?: string;
  id: number;
  name: string;
  shortName: string;
}

/**
 * Defines the work order organization client address interface
 */
export interface WorkOrderOrganizationAddress {
  city: string;
  country: string;
  county: string;
  default: boolean;
  description: string;
  fullAddress: string;
  id: number;
  name: string;
  number: string;
  postCode: string;
  street: string;
}

/**
 * Defines the work order organization client company interface
 */
export interface WorkOrderOrganizationCompany {
  email: string;
  id: number;
  name: string;
  phone: string;
  regNumber: string;
  vatNumber: string;
  webpage: string;
}

/**
 * Defines the work order organization client interface
 */
export interface WorkOrderOrganizationClient {
  company?: WorkOrderOrganizationCompany;
  address?: WorkOrderOrganizationAddress;
  delegateName: string;
  description?: string;
  email?: string;
  fidelity: boolean;
  id: number;
  isCompany: boolean;
  loyaltyCard?: any;
  name: string;
  organizationId: number;
  phone?: string;
  type: string;
  uuid: string;
}

/**
 * Defines the work order original product interface
 */
export interface WorkOrderOriginalProduct {
  carTypeId?: number;
  description?: string;
  id: number;
  isCountPrice: boolean;
  isExpendable: boolean;
  isService: boolean;
  name: string;
  organizationId: number;
  price: number;
  priceConditions: any[];
  um: string;
  workOrderTypeId: number;
}

/**
 * Defines the work order product interface
 */
export interface WorkOrderProduct {
  id: number;
  isCountPrice: number | boolean;
  isExpendable: number | boolean;
  isService: number | boolean;
  name: string;
  organizationProductId?: number;
  price: number;
  quantity: number;
  total: number;
  originalProduct?: WorkOrderOriginalProduct;
  workOrderId?: number;
  isTyre?: boolean;
}

/**
 * Defines the work order tyre service interface
 */
export interface WorkOrderTyreService {
  boltTorque?: string;
  carKm?: string;
  id: number;
  newTyre: boolean;
  tyreHeight?: string;
  tyrePressure?: string;
  tyreRim?: string;
  tyreWaste?: string;
  tyreWidth?: string;
  wheelType: string;
  dotNumber?: string;
  tyreDimensions: TyreDimension[];
}

/**
 * Defines the work order car service interface
 */
export interface WorkOrderCarService {
  carKm?: string;
  carType: string;
  customerRequest: string[];
  id: number;
  make: string;
  mention: string;
  model: string;
  modelType: string;
  providerProposal: string[];
  vinNumber: string;
  year: string;
}

/**
 * Defines the work order tyre hotel interface
 */
export interface WorkOrderTyreHotel {
  carNumber: string; // plate number
  client: WorkOrderOrganizationClient;
  clientName: string;
  createdAt: string;
  description?: string;
  id: number;
  organizationClientId: number;
  organizationId: number;
  tyreHeight: string;
  tyreName: string;
  tyreQuantity: number;
  tyreDimensions?: string; // frontend only
  tyreRim: string;
  tyreWidth: string;
  workOrderId: number;
  workOrderUuid: string;
  metaData?: { [key: string]: any };
  index: number; // frontend only
  carModel?: string;
  carMake?: string;
  status: string;
  closedAt?: string;
}

/**
 * Defines the work order type interface
 */
export interface WorkOrderType {
  description?: string;
  id: number;
  name: string;
  shortName: string;
}

/**
 * Defines the work order worker interface
 */
export interface WorkOrderWorker {
  birthDate: string;
  dailyNorm: string;
  function?: string;
  gender?: string;
  id: number;
  monthlyNorm: string;
  name: string;
  organizationId: number;
  userId?: number;
  uuid: string;
  index?: number;
}

/**
 * Defines the work order event data interface
 */
export interface WorkOrderEvent {
  type: ListenType;
  createdAt: string;
  updatedAt: string;
}

/**
 * Defines the timesheet event data interface
 */
export interface TimesheetEvent {
  accountId: number;
  createdAt: string;
  updatedAt: string;
  id: string | number;
  startDate: string;
  status: string;
  userId: string | number;
}

/**
 * Defines the Work Order interface
 */
export interface WorkOrder {
  car: WorkOrderCar;
  carPlateNumber: string;
  carTypeId: number;
  carType: WorkOrderCarType;
  clientName: string;
  createdAt: string;
  delegateName?: string;
  description?: string;
  discount: number;
  duration: string;
  endDate: string;
  finished: string;
  id: number;
  phone?: string;
  organizationClient: WorkOrderOrganizationClient;
  organizationClientId: number;
  organizationId: number;
  paymentType?: PaymentType;
  paymentTypeId?: number;
  products: WorkOrderProduct[];
  startDate: string;
  status: string;
  subtotal: number;
  total: number;
  tax: number;
  tyreHotels: WorkOrderTyreHotel[];
  tyreService: WorkOrderTyreService;
  carService: WorkOrderCarService;
  updatedAt: string;
  uuid: string;
  itp?: boolean;
  workOrderType: WorkOrderType;
  workOrderTypeId: number;
  workers: WorkOrderWorker[];
  index?: number; // frontend only
  statusName?: string; // frontend only
  paymentTypeName?: string; // frontend only
  tyreDimensions?: string; // frontend only
  carData?: string; // frontend only
  event?: WorkOrderEvent; // frontend  only
  details?: WorkOrder; // drafts only
  workOrderTypeName?: string; // frontend only
  metaData?: { [key: string]: string };
  tyreHotel?: {
    tyreHeight?: string;
    tyreWidth?: string;
    tyreRim?: string;
    tyreName?: string;
    tyreQuantity?: string;
    description?: string;
  };
}

/**
 * Defines the Slim Work order interface
 */
export interface WorkOrderSlim {
  carPlateNumber: string;
  carTypeId: number;
  clientName: string;
  createdAt: string;
  delegateName?: string;
  deletedAt?: string;
  description?: string;
  discount: number;
  duration: string;
  endDate: string;
  finished: string;
  id: number;
  organizationClientId?: number;
  organizationId: number;
  paymentTypeId?: number;
  startDate: string;
  subtotal: number;
  tax: number;
  total: number;
  updatedAt: string;
  uuid: string;
  workOrderTypeId: number;
}

/**
 * Defines the breadcrumb path interface
 */
export interface BreadcrumbPath {
  label: string;
  link: boolean;
  url?: Paths;
}

/**
 * Defines the select option interface
 */
export interface SelectOption {
  label: string;
  value: any;
}

/**
 * Defines the work order product form item interface
 */
export interface WorkOrderProductFormItem {
  type: string;
  name: string;
  quantity: string;
  price: string;
  total: string;
}

/**
 * Defines the forms enum
 */
export type FormType = "CREATE_WORK_ORDER";

/**
 * Defines the default forms enum
 */
export type DefaultForms = {
  [key in FormType]: {
    [key: string]: any;
  };
};

/**
 * Defines the Hash Map interface
 */
export interface HashMap<T> {
  [key: string]: T;
}

/**
 * Defines the Tab interface
 */
export interface Tab {
  key: string;
  label: string;
  index: number;
}

/**
 * Defines the loyalty card
 */
export interface LoyaltyCard {
  cardNumber: string;
  discountType: "PERCENT" | "VALUE";
  discountValue: number;
  id: number;
  isActive: boolean;
  organizationClientId: number;
  uuid: string;
  index: number; // Frontend only
  clientName?: string; // Frontend only
}

/**
 * Defines the listen types enum
 */
export enum ListenType {
  TEST = "TEST",
  WORK_ORDER_CREATED = "WORK_ORDER_CREATED",
  WORK_ORDER_UPDATED = "WORK_ORDER_UPDATED",
  WORK_ORDER_DELETED = "WORK_ORDER_DELETED",
  LOYALTY_CARD_CREATED = "LOYALTY_CARD_CREATED",
  LOYALTY_CARD_UPDATED = "LOYALTY_CARD_UPDATED",
  LOYALTY_CARD_DELETED = "LOYALTY_CARD_DELETED",
  ORGANIZATION_CLIENT_CREATED = "ORGANIZATION_CLIENT_CREATED",
  ORGANIZATION_CLIENT_UPDATED = "ORGANIZATION_CLIENT_UPDATED",
  ORGANIZATION_CLIENT_DELETED = "ORGANIZATION_CLIENT_DELETED",
  ORGANIZATION_PRODUCT_CREATED = "ORGANIZATION_PRODUCT_CREATED",
  ORGANIZATION_PRODUCT_UPDATED = "ORGANIZATION_PRODUCT_UPDATED",
  ORGANIZATION_PRODUCT_DELETED = "ORGANIZATION_PRODUCT_DELETED",
  TYRE_HOTEL_CREATED = "TYRE_HOTEL_CREATED",
  TYRE_HOTEL_UPDATED = "TYRE_HOTEL_UPDATED",
  TYRE_HOTEL_DELETED = "TYRE_HOTEL_DELETED",
  TIME_SHEET_UPDATED = "TIME_SHEET_UPDATED",
  TIME_SHEET_CREATED = "TIME_SHEET_CREATED",
  WORK_ORDER = "WORK_ORDER",
  GENERAL = "GENERAL",
}

/**
 * Defines the event interface
 */
export interface Event<T> {
  type: ListenType;
  data: T;
}

/**
 * Defines the work orders cache interface
 */
export interface TableCache<T> {
  rows: T[];
  filters: FilterModel[];
  total: number;
  pageCount: number;
  orderBy: string;
  orderDir: SortOrder;
}

/**
 * Defines the work orders cache interface
 */
export type WorkOrdersCache = TableCache<WorkOrder>;

/**
 * Defines the clients cache interface
 */
export type ClientsCache = TableCache<Client>;

/**
 * Defines the tyre hotels cache interface
 */
export type TyreHotelsCache = TableCache<WorkOrderTyreHotel>;

/**
 * Defines the loyalty cards cache interface
 */
export type LoyaltyCardsCache = TableCache<LoyaltyCard>;

/**
 * Defines the users cache interface
 */
export type UsersCache = TableCache<AccountUser>;

/**
 * Defines the users cache interface
 */
export type WorkersCache = TableCache<WorkOrderWorker>;

/**
 * Defines the products cache interface
 */
export type ProductsCache = TableCache<Product>;

/**
 * Defines the work overview cache
 */
export interface WorkOverviewCache {
  view: "kanban" | "table";
  workOrderType: "draft" | "live";
}

/**
 * Defines the live work orders cache
 */
export interface LiveWorkOrdersCache {
  rows: WorkOrder[];
  filters: GenericFilterItem[];
  filterModels: FilterModel[];
  searchValue: string;
}

/**
 * Defines the Cache interface
 */
export interface Cache {
  workOrders: WorkOrder[];
  workOrdersTable?: WorkOrdersCache;
  clientsTable?: ClientsCache;
  tyreHotelsTable?: TyreHotelsCache;
  loyaltyCardsTable?: LoyaltyCardsCache;
  usersTable?: UsersCache;
  workersTable?: WorkersCache;
  productsTable?: ProductsCache;
  organizations: UserOrganization[];
  carTypes: CarType[];
  paymentTypes: PaymentType[];
  appointmentGroups: AppointmentGroup[];
  workOverview?: WorkOverviewCache;
  liveWorkOrders?: WorkOrder[];
  suspendedWorkOrders?: WorkOrder[];
  draftWorkOrders?: WorkOrdersCache;
}

/**
 * Defines the Notification interface
 */
export interface Notification<T> {
  id: string;
  type: ListenType;
  data: T;
  createdAt: Date;
  read: boolean;
  seen: boolean;
}

/**
 * Defines the Work Overview Filter interface
 */
export interface GenericFilterItem {
  labelKey: string;
  value: string;
  active: boolean;
  variant: ButtonVariant;
  textColor: Color;
  textColorActive: Color;
}

/**
 * Defines a generic object interface
 */
export interface GenericObject {
  [key: string]: any;
}

/**
 * Defines the Auth interface
 */
export interface Auth {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  user?: {
    id: number;
    organizationId: number;
    type: UserRole;
    username: string;
    uuid: string;
    avatarConfig?: AvatarOption;
    email?: string;
    firstName?: string;
    lastName?: string;
    lastTimeSheet?: UserTimesheet;
  };
}

/**
 * Defines the create client interface
 */
export interface CreateClient {
  client: {
    isCompany: boolean;
    fidelity?: boolean;
    organizationId?: string | number;
    name: string;
    delegateName?: string;
    description?: string;
    email?: string;
    phone?: string;
  };
  company?: {
    vatNumber?: string;
    regNumber?: string;
    email?: string;
    phone?: string;
  };
  address?: {
    city?: string;
    number?: string;
    street?: string;
    county?: string;
    country?: string;
  };
}

/**
 * Defines the update payment type body
 */
export interface UpdatePaymentTypeBody {
  name: string;
  shortName: string;
  description?: string;
}

/**
 * Defines the update payment type body
 */
export interface UpdateAppointmentGroupBody extends UpdatePaymentTypeBody {
  organizationId: number | string;
}

/**
 * Defines the Timesheet interface
 */
export interface Timesheet {
  id: string | number;
  status: string;
  startDate: string;
  endDate: string;
}

/**
 * Defines the User Timesheet interface
 */
export interface UserTimesheet {
  accountId: number;
  createdAt: string;
  endDate: string;
  id: number;
  startDate: string;
  status: string;
  updatedAt: string;
  userId: number;
  organizationId: number;
  index: number; // Frontend only
  user: {
    firstName: string;
    lastName: string;
    type: UserRole;
    username: string;
    uuid: string;
  };
  firstName: string; // Frontend only
  lastName: string; // Frontend only
  username: string; // Frontend only
  role: string; // Frontend only
  duration: string; // Frontend only
}

/**
 * Defines the Timesheet option interface
 */
export interface TimesheetOption {
  label: string;
  value: string;
  variant: "success" | "error" | "warning" | "pending";
}

/**
 * Defines the Guaranty interface
 */
export interface Guaranty {
  index: number; // frontend only
  accountId: number;
  clientId: number;
  clientName: string;
  comments: any[];
  createdAt: string;
  createdBy: number;
  createdByName: string; // frontend only
  description: string;
  detailedData: any[];
  id: number;
  organizationId: number;
  updatedAt: string;
  workOrderUuid: string;
  modelId: number;
  modelName: string;
}

/**
 * Defines the Notification settings interface
 */
export interface NotificationSettings {
  workOrders: boolean;
  products: boolean;
  clients: boolean;
  tyreHotels: boolean;
}

/**
 * Defines the work order draft request body
 */
export interface WorkOrderDraftRequestBody<ProductType> {
  workOrder: {
    organizationClientId?: number;
    carPlateNumber: string;
    clientName?: string;
    delegateName?: string;
    carTypeId?: string | number;
    workOrderTypeId: string | number;
    duration: string;
    subtotal: string | number;
    discount: string | number;
    tax: string | number;
    total: string | number;
    startDate: string;
    endDate: string;
    phone?: string;
    description?: string;
  };
  details: {
    workers: number[];
    car: {
      plateNumber: string;
      type: string;
      make?: string;
      model?: string;
      vinNumber?: string;
      description?: string;
      manufacturingYear?: string | number;
    };
    tyreService: {
      wheelType: string; // NOT ID
      boltTorque?: string | number;
      carKm?: string | number;
      newTyre?: boolean;
      tyreHeight?: string | number;
      tyreWaste?: string | number;
      tyreWidth?: string | number;
      tyreRim?: string | number;
      tyrePressure?: string | number;
    };
    products: ProductType;
    tyreHotel?: {
      tyreHeight?: string;
      tyreWidth?: string;
      tyreRim?: string;
      tyreName?: string;
      tyreQuantity?: string;
      description?: string;
    };
  };
}

/**
 * Defines the work order form body interface
 */
export interface WorkOrderFormBody {
  workOrder: {
    carTypeId: string | number;
    clientId: string | number;
    clientName: string;
    delegateName: string;
    description: string;
    discount: string | number;
    duration: string;
    endDate: string;
    organizationClientId: string;
    startDate: string;
    subtotal: string | number;
    total: string | number;
    tax: string | number;
    phone?: string;
    workOrderTypeId: string | number;
    metaData?: any;
    itp?: boolean;
  };
  car: {
    plateNumber: string;
    type: string;
    vinNumber: string;
    description: string;
    make: string;
    model: string;
    manufacturingYear?: string;
  };
  tyreService: {
    boltTorque: string;
    carKm: string;
    newTyre: boolean;
    tyreHeight: string;
    tyrePressure: string;
    tyreRim: string;
    tyreWaste: string;
    tyreWidth: string;
    dotNumber: string;
    wheelType: string | number;
    tyreDimensions: TyreDimension[];
  };
  carService: {
    mention: string;
    customerRequest: string[];
    providerProposal: string[];
    modelType: string;
    carKm?: string;
  };
  tyreHotelEnabled?: boolean;
  tyreHotel?: {
    description: string;
    tyreHeight: string;
    tyreName: string;
    tyreQuantity: string;
    tyreRim: string;
    tyreWidth: string;
  };
  workers: number[];
  products: ProductForm[];
}

/**
 * Defines the Timesheet Report item interface
 */
export interface TimesheetReportItem {
  id: number;
  name: string;
  organizationId: number;
  timeSheets: TimesheetTotalization;
  type: string;
  username: string;
  uuid: string;
}

/**
 * Defines the Timesheet Report interface
 */
export interface TimesheetReport {
  items: TimesheetReportItem[];
  total: TimesheetTotalization;
  successMessage: string[];
}

/**
 * Defines the Timesheet Report Totalization interface
 */
export interface TimesheetTotalization {
  [key: string]: number;
}

/**
 * Defines the Appointment interface
 */
export interface Appointment {
  appointmentGroupId: number;
  carModel: string;
  carMake?: string; // backend soon
  carPlateNumber: string;
  client: Client;
  clientName: string;
  createdAt: string;
  createdBy: AccountUser;
  description: string;
  duration: number;
  from: string;
  to: string;
  id: number;
  order: number;
  organizationId: number;
  phone: string;
  status: string;
  requestedServices: any[];
  index?: number; // frontend only
  time?: string; // frontend only
  date?: string; // frontend only
  timestamp?: number; // frontend only
  smsLanguage?: string;
  options?: {
    tyreHotel?: boolean;
    newTyre?: boolean;
    withRim?: boolean;
  };
}

/**
 * Defines the user registration body interface
 */
export interface RegisterUserBody {
  email: string;
  code: string;
  userData: {
    username: string;
    password: string;
    passwordConfirmation: string;
  };
  companyData: {
    companyName: string;
    vatNumber: string;
    regNumber: string;
    country: string;
    city: string;
    county: string;
    street: string | null;
    postCode: string | null;
    streetNumber: string | null;
    emailAddress: string | null;
    phoneNumber: string | null;
    website: string | null;
    timeSheet: string | null;
  };
}

/*
 * Defines the Socket Event interface
 */
export interface SocketEvent<T> {
  event: string;
  model: T;
  modelName: string;
}

/**
 * Defines the work order socket event type
 */
export type WorkOrderSocketEvent = SocketEvent<WorkOrder>;

/**
 * Defines the tyre dimension interface
 */
export interface TyreDimension {
  tyreWidth?: string;
  tyreHeight?: string;
  tyreRim?: string;
  tyrePressure?: string;
  boltTorque?: string;
  dotNumber?: string;
  tyreQuantity?: string;
}

/**
 * Defines the account setting interface
 */
export interface AccountSetting {
  id: number;
  name: string;
  value: string;
  organizationId?: number;
  userId?: number;
  description?: string;
  options?: {
    [key: string]: any;
  };
}

/**
 * Defines the Car Model interface
 */
export interface CarModel {
  name: string;
  makeId: number;
  id?: number;
}

/**
 * Defines the Car Name interface
 */
export interface CarName {
  id: number;
  name: string;
}

/**
 * Defines the Account Settings Lists interface
 */
export interface AccountSettingsLists {
  carModels: CarModel[];
  carNames: CarName[];
}

/**
 * Defines the plate number search result interface
 */
export interface PlateNumberSearchResult {
  id: number;
  carPlateNumber: string;
  carTypeId: number;
  clientName: string;
  delegateName?: string;
  organizationClientId: number;
  organizationId: number;
  uuid: string;
  workOrderTypeId: number;
  phone?: string;
  car: {
    id: number;
    carKm?: number | string;
    make?: string;
    manufacturingYear?: string | number;
    model?: string;
    type?: string;
    vinNumber?: string;
  };
  tyreService: {
    id: number;
    tyreDimensions: TyreDimension[];
    tyreWidth?: string;
    tyreHeight?: string;
    tyreRim?: string;
    tyrePressure?: string;
    boltTorque?: string;
    dotNumber?: string;
    wheelType?: string;
    workOrderId: number;
  };
}

/**
 * Defines the Customer order interface
 */
export interface CustomerOrder {
  id: number;
  appointmentGroupId: number | null;
  carMake: string;
  carModel: string;
  carPlateNumber: string;
  clientName: string;
  createdAt: string;
  description: string;
  onDate: string;
  order: number;
  organizationId: number;
  userId: number;
  badgeColor?: BadgeColor; // frontend only
  metaData?: GenericObject;
  newTyre?: boolean;
  tyreMake?: string;
  tyreModel?: string;
  colorCode?: string;
}

/**
 * Defines the Delete Request interface
 */
export interface DeleteRequest {
  id: number;
  accountId: number;
  status: "new" | "pending" | "active" | "error" | "done";
  payload: any;
}

/**
 * Defines the Guest Appointment Group
 */
export interface GuestAppointmentGroup {
  id: number;
  name: string;
}

/**
 * Defines the Guest Organization
 */
export interface GuestOrganization {
  id: number;
  uuid: string;
  name: string;
  slug?: string;
  appointmentGroups: GuestAppointmentGroup[];
  publicSettings: GenericObject | null;
}

/**
 * Defines the Guest Appointment
 */
export interface GuestAppointment {
  duration: number;
  from: string;
  to: string;
  order: number;
  appointmentGroupId: number;
}

/**
 * Defines the Guest car model
 */
export interface GuestCarModel {
  id?: number;
  makeId: number;
  name: string;
}

/**
 * Defines the Guest car name
 */
export interface GuestCarName {
  id: number;
  name: string;
}

export type SMSLanguage = "ro" | "hu" | "en";

export type SMSMessageOptions = {
  [lang in SMSLanguage]: string | null;
};
