import { useEffect, useMemo } from "react";

/**
 * Imports hooks
 */
import { useTheme } from "@mui/material/styles";
import { useActions, useSelector, useUserUtils, useWindowDimensions } from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const WorkOverviewProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  const { view, workOrderType } = useSelector((state) => state.workOverview);
  const { updateWorkOverviewView, updateWorkOverviewType } = useActions();

  /**
   * Gets the users utils
   */
  const { isUserWorker } = useUserUtils();

  /**
   * Gets the theme
   */
  const theme = useTheme();

  /**
   * Gets the width of the screen
   */
  const { width } = useWindowDimensions();

  /**
   * Checks if the screen is mobile
   */
  const isMobile = useMemo(() => {
    return width <= theme.breakpoints.values.md;
    // eslint-disable-next-line
  }, [width]);

  /**
   * Handles changing the active view
   */
  const changeView = (value: "kanban" | "table") => {
    updateWorkOverviewView(value);
  };

  /**
   * Handles changing the work order type
   */
  const changeWorkOrderType = (value: "draft" | "live") => {
    updateWorkOverviewType(value);
  };

  /**
   * Handles showing the live work orders
   */
  const showLiveWorkOrders = () => {
    return workOrderType === "live" || isUserWorker;
  };

  /**
   * Handles showing the draft work orders
   */
  const showDraftWorkOrders = () => {
    return workOrderType === "draft" && view === "table" && !isUserWorker;
  };

  /**
   * Updates the view mode when the work order drafts are viewed
   */
  useEffect(() => {
    if (view === "kanban" && workOrderType === "draft") {
      updateWorkOverviewView("table");
    }
    // eslint-disable-next-line
  }, [view, workOrderType]);

  /**
   * Workers are not allowed to use drafts
   */
  useEffect(() => {
    if (isUserWorker && workOrderType === "draft") {
      updateWorkOverviewType("live");
      updateWorkOverviewView("table");
    }
    // eslint-disable-next-line
  }, [workOrderType]);

  /**
   * Forces the view to be table on mobile/tablet
   */
  useEffect(() => {
    if (view === "kanban" && isMobile) {
      updateWorkOverviewView("table");
    }
    // eslint-disable-next-line
  }, [isMobile, view]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    changeView,
    changeWorkOrderType,
    showLiveWorkOrders,
    showDraftWorkOrders,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
