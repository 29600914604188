import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { ReduxSlice } from "../../features";
import { PayloadAction } from "@reduxjs/toolkit";
import { AccountState } from "./account.types";
import { AppThunk } from "../../../redux";
import {
  User,
  Worker,
  Account,
  CarType,
  WheelType,
  Timesheet,
  AccountUser,
  PaymentType,
  UserOrganization,
  AppointmentGroup,
  Product,
  AccountSetting,
  AvatarOption,
} from "../../../types";

/**
 * Imports APIs
 */
import { fetchAccountInformation, searchProducts } from "../../features";

/**
 * Imports utils
 */
import {
  formatCarModels,
  preloadLockedState,
  preloadDeletedState,
  buildUserAccountData,
  getAppointmentGroups,
  preloadAvatarConfigState,
} from "./account.utils";
import { uniqBy } from "lodash";
import { toHashMap } from "../../utils";

/**
 * Defines the account initial state
 */
const initialState: AccountState = {
  storedAvatarConfig: preloadAvatarConfigState(),
  locked: preloadLockedState(),
  isDeleted: preloadDeletedState(),
  userInitialized: false,
  accountSettings: [],
  modules: [],
  appointmentGroups: [],
  workOrderTypes: [],
  paymentTypes: [],
  roles: [],
  wheelTypes: [],
  accountUsers: [],
  carTypes: [],
  workers: [],
  organizations: [],
  carNames: [],
  carModels: [],
  products: [],
  productsHashMap: {},
  timesheet: undefined,
  accountInformation: {
    activeFrom: "",
    activeTo: "",
    createdAt: "",
    description: undefined,
    id: -1,
    name: "",
    status: "",
    uuid: "",
    username: "",
    organization: undefined,
    organizations: [],
  },
  activePackage: {
    currency: "EUR",
    description: "",
    duration: "",
    name: "",
    order: -1,
    price: -1,
    uuid: -1,
  },
  smsDefaultMessages: {
    smsMessageAppointmentCreated: {
      ro: "",
      hu: "",
      en: "",
    },
    smsMessageAppointmentDeleted: {
      ro: "",
      hu: "",
      en: "",
    },
    smsMessageAppointmentNotification: {
      ro: "",
      hu: "",
      en: "",
    },
    smsMessageItpNotification: {
      ro: "",
      hu: "",
      en: "",
    },
  },
};

/**
 * Handles creating the a redux state slice
 */
export const accountSlice = createSlice({
  name: ReduxSlice.Account,
  initialState,
  reducers: {
    updateAccountPaymentTypes: (
      state,
      action: PayloadAction<PaymentType[]>,
    ) => {
      state.paymentTypes = action.payload;
    },
    updateAccountWheelTypes: (state, action: PayloadAction<WheelType[]>) => {
      state.wheelTypes = action.payload;
    },
    updateAccountUsers: (state, action: PayloadAction<AccountUser[]>) => {
      state.accountUsers = action.payload;
    },
    updateAccountCarTypes: (state, action: PayloadAction<CarType[]>) => {
      state.carTypes = action.payload;
    },
    updateAccountWorkers: (state, action: PayloadAction<Worker[]>) => {
      state.workers = action.payload;
    },
    updateAccountOrganizations: (
      state,
      action: PayloadAction<UserOrganization[]>,
    ) => {
      state.organizations = action.payload;
    },
    updateAccountTimesheet: (
      state,
      action: PayloadAction<Timesheet | undefined>,
    ) => {
      state.timesheet = action.payload;
    },
    _updateUserAccountInformation: (state, action: PayloadAction<User>) => {
      state.accountInformation = action.payload;
    },
    updateUserAccountDeleted: (state, action: PayloadAction<boolean>) => {
      state.isDeleted = action.payload;
    },
    updateAppointmentGroups: (state, action: PayloadAction<Account>) => {
      state.appointmentGroups = getAppointmentGroups(action.payload);
    },
    _updateAppointmentGroups: (
      state,
      action: PayloadAction<AppointmentGroup[]>,
    ) => {
      state.appointmentGroups = action.payload;
    },
    updateProductsWithHash: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
      state.productsHashMap = toHashMap(action.payload, "id");
    },
    updateAccountSetting: (state, action: PayloadAction<AccountSetting>) => {
      const { id } = action.payload;
      const settingIndex = state.accountSettings.findIndex(
        (setting) => setting.id === id,
      );

      if (settingIndex !== -1) {
        state.accountSettings[settingIndex] = action.payload;
      } else {
        state.accountSettings.push(action.payload);
      }
    },
    updateLockedState: (state, action: PayloadAction<boolean>) => {
      state.locked = action.payload;
    },
    updateAvatarConfig: (state, action: PayloadAction<AvatarOption>) => {
      state.storedAvatarConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fetchAccountInformation.matchFulfilled,
      (state, { payload }) => {
        const {
          user,
          account,
          roles,
          modules,
          lists,
          activePackage,
          smsDefaultMessages,
        } = payload.data;
        const {
          users,
          workers,
          carTypes,
          wheelTypes,
          paymentTypes,
          organizations,
          workOrderTypes,
          accountSettings,
        } = account;
        const { carNames } = lists;
        const accountInformation = buildUserAccountData(user, account);
        const carModels = formatCarModels(lists.carModels);

        if (activePackage) {
          state.activePackage = activePackage;
        }

        state.userInitialized = true;
        state.timesheet = accountInformation.lastTimeSheet;
        state.accountSettings = accountSettings;
        state.roles = roles || [];
        state.modules = modules || [];
        state.workers = workers || [];
        state.carNames = carNames || [];
        state.accountUsers = users || [];
        state.wheelTypes = wheelTypes || [];
        state.paymentTypes = paymentTypes || [];
        state.organizations = organizations || [];
        state.workOrderTypes = workOrderTypes || []; // needs local storage
        state.carTypes = uniqBy(carTypes, "name");
        state.carModels = carModels || [];
        state.accountInformation = accountInformation;
        state.appointmentGroups = getAppointmentGroups(account);
        state.isDeleted = accountInformation.status === "deleted";
        state.smsDefaultMessages = smsDefaultMessages;
      },
    );
    builder.addMatcher(searchProducts.matchFulfilled, (state, { payload }) => {
      const { items } = payload.data;

      if (items.length > 0) {
        state.products = items;
        state.productsHashMap = toHashMap(items, "id");
      }
    });
  },
});

/**
 * Handles updating the account information
 */
export const updateUserAccountInformation =
  (data: Account): AppThunk =>
  (dispatch, getState) => {
    const auth = getState().auth;
    const user = auth.user as unknown as User;

    if (user) {
      const accountInformation = buildUserAccountData(user, data);

      dispatch(
        accountActionCreators._updateUserAccountInformation(accountInformation),
      );
    }
  };

/**
 * Exports the action-creators
 */
export const accountActionCreators = accountSlice.actions;

/**
 * Exports the reducer
 */
export const accountReducer = accountSlice.reducer;
