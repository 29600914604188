/**
 * Imports components
 */
import {
  ThemeProvider,
  LanguageProvider,
  DeviceProvider,
  ApolloProvider,
} from "../../hooks";

/**
 * Displays the component
 */
export const Providers: React.FC = (props) => {
  const { children } = props;

  return (
    <LanguageProvider>
      <ThemeProvider>
        <ApolloProvider>
          <DeviceProvider>{children}</DeviceProvider>
        </ApolloProvider>
      </ThemeProvider>
    </LanguageProvider>
  );
};
