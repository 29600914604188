/**
 * Imports types
 */
import { SortOrder } from "../types";

/**
 * Defines the default page in tables
 */
export const DEFAULT_PAGE = 1;

/**
 * Defines the default rows per page in tables
 */
export const DEFAULT_ROWS_PER_PAGE = 15;

/**
 * Defines the default sort order
 */
export const DEFAULT_SORT_ORDER: SortOrder = "desc";

/**
 * Defines the default order by field
 */
export const DEFAULT_ORDER_BY: string | number = "created_at";

/**
 * Defines the default date format
 */
export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

/**
 * Defines the currency
 */
export const CURRENCY = "RON";

/**
 * Defines the unit of measure
 */
export const UNIT_OF_MEASURE = "BUC";

/**
 * Defines the max page size
 */
export const MAX_PAGE_SIZE = 1000000;

/**
 * Defines the notifications expiry time
 */
export const EXPIRY_TIME = 1000 * 60 * 60 * 24; // 24 hours

/**
 * Defines the idle timeout
 */
export const IDLE_TIMEOUT = 1000 * 60 * 60 * 2; // 2 hours

/**
 * Defines the diacritics map
 */
export const DICT = { â: "a", ă: "a", ș: "s", î: "i", ț: "t" };

/**
 * Defines the special services
 */
export const SPECIAL_SERVICES = [
  "Demontat+Montat Anvelopa",
  "Demontat+Montat Roata",
  "Echilibrat Roti",
];

/**
 * Defines the default avatar config
 */
export const DEFAULT_AVATAR_CONFIG = {
  color: "#1E697A",
  hover: "#16A3B0",
};

/**
 * Defines the work order tabs
 */
export enum WORK_ORDER_TABS {
  GENERAL_DATA = "GENERAL_DATA",
  VEHICLE_DATA = "VEHICLE_DATA",
  PRODUCTS_AND_SERVICES = "PRODUCTS_AND_SERVICES",
  SUMMARY = "SUMMARY",
}

/**
 * Defines the work order ITP tabs
 */
export enum WORK_ORDER_ITP_TABS {
  ITP_FORM = "ITP_FORM",
  SUMMARY = "SUMMARY",
}

/**
 * Defines the timesheet statuses
 */
export enum TIMESHEET {
  WORKING = "WORKING",
  BREAK = "BREAK",
  AWAY = "AWAY",
  LUNCH = "LUNCH",
  ON_HOLIDAY = "ON_HOLIDAY",
  GONE_HOME = "GONE_HOME",
}

/**
 * Defines the help center url
 */
export const HELP_CENTER_URL = "https://help.atelierulmeu.ro/";

/**
 * Defines the default active view in the calendar (appointments)
 */
export const DEFAULT_CALENDAR_ACTIVE_VIEW = "Work Week";

/**
 * Defines the auth key
 */
export const AUTH_KEY = "atelierulmeu-auth";

/**
 * Defines the ITP Work order type value
 */
export const ITP_WORK_ORDER_TYPE_VALUE = 999;

/**
 * Defines the account deleted local storage key
 */
export const ACCOUNT_DELETED_KEY = "account-deleted";

export const DEFAULT_WORKORDER_TYPE_KEY = "default-workorder-type";
export const LOCKED_KEY = "locked";
export const AVATAR_CONFIG_KEY = "avatar-config";
export const DETAILED_REPORTS = "detailed-reports";
export const NOTIFICATIONS_KEY = "notifications";
export const NOTIFICATION_VIEW_KEY = "notification-view";
export const NOTIFICATION_SETTINGS_KEY = "notification-settings";
export const WORKOVERVIEW_VIEW_KEY = "workOverview-view";
export const WORKOVERVIEW_TYPE_KEY = "workOverview-type";
export const STATISTICS_KEY = "statistics";
export const WORKOORDER_DRAFT_KEY = "workorder-draft";
export const APPOINTMENTS_VIEW_KEY = "appointmentsView";
export const APPOINTMENTS_ACTIVE_ORG_KEY = "appointmentsActiveOrg";
export const APPOINTMENTS_RESOURCES_KEY = "appointmentsResources";
export const APPOINTMENTS_WITH_GROUPING_KEY = "appointmentsWithGrouping";
export const APPOINTMENTS_ACTIVE_GROUPS_KEY = "appointmentsActiveGroups";
export const APPOINTMENTS_INCLUDE_GROUPLESS_KEY =
  "appointmentsIncludeGroupless";
