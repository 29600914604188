import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import {
  useSelector,
  useApi,
  useSearch,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FilterModel, WorkOrder, TableData } from "../../types";
import {
  SearchWorkOrderDraftsBody,
  SearchWorkOrderDraftsOnSuccess,
  RequestOnError,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const DraftWorkOrdersProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the draft work orders
   */
  const [draftWorkOrders, setDraftWorkOrders] = useState<WorkOrder[]>([]);

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the table rows loading
   */
  const [tableRowsLoading, setTableRowsLoading] = useState(false);

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the total draftWorkOrders
   */
  const [totalDrafts, setTotalDrafts] = useState(0);

  /**
   * Initializes the order by state
   */
  const [orderBy, setOrderBy] = useState("created_at");

  /**
   * Initializes the order dir state
   */
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("desc");

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Initializes the filters changed flag
   */
  const [filtersChanged, setFiltersChanged] = useState(false);

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the filter model utility hook
   */
  const {
    formatActiveFilters,
    addOrReplaceFilter,
    createFilter,
    removeFilter,
  } = useFilterModelsUtils();

  /**
   * Gets the user utility functions
   */
  const { getUserOrganization, getDefaultWorkOrderType } = useUserUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchWorkOrderDrafts(filterModels, 1);
    setActiveFilters(filterModels);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    if (filter.field.includes("quick_text")) {
      setFiltersChanged(true);
    }

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setLoading(true);
    setFiltersChanged(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchWorkOrderDrafts(filters, 1);
    }, 500);
  };

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    /**
     * Gets the default work order type (Vulcanizare)
     */
    const workOrderType = getDefaultWorkOrderType();

    /**
     * Creates the filter models
     */
    if (organization && workOrderType) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      /**
       * Defines the work order type filter model
       */
      const workOrderTypeFilterModel = createFilter({
        field: "work_order_type_id",
        selected: workOrderType.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
      models.push(workOrderTypeFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = (error) => {
    setLoading(false);
    setTableRowsLoading(false);
    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });
  };

  /**
   * Handles searching for draft work orders
   */
  const searchWorkOrderDrafts = async (
    filters: FilterModel[],
    page?: number,
  ) => {
    if (filters.length > 0) {
      /**
       * Defines the request body
       */
      const reqBody: SearchWorkOrderDraftsBody = {
        models: filters.filter((model) => !model.displayOnly),
        order_by: orderBy,
        order_dir: orderDir,
        page_count: page || pageCount,
        page_size: 15,
      };

      /**
       * Defines the api call success callback
       */
      const onRequestSuccess: SearchWorkOrderDraftsOnSuccess = (response) => {
        if (response && response.data) {
          const { data } = response;
          const { items, pageCount, orderBy, orderDir, total } = data;

          setDraftWorkOrders(items);
          setPageCount(pageCount);
          setOrderBy(orderBy);
          setOrderDir(orderDir);
          setTotalDrafts(total);
          setLoading(false);
          setTableRowsLoading(false);
        }
      };

      await apiCalls.searchWorkOrderDrafts(
        reqBody,
        onRequestSuccess,
        onRequestError,
      );
    }
  };

  /**
   * Handles updating the work order in the table
   */
  const updateWorkOrder = (workOrder: WorkOrder) => {
    const index = findIndex(draftWorkOrders, { id: workOrder.id });
    const clonedWorkOrders = cloneDeep(draftWorkOrders);
    clonedWorkOrders[index] = workOrder;

    setDraftWorkOrders(clonedWorkOrders);
  };

  /**
   * Handles updating the current page state
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Handles sorting, updates states
   */
  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles generating the initial report based on default filters
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchWorkOrderDrafts(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchWorkOrderDrafts(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    orderBy,
    loading,
    orderDir,
    pageCount,
    draftWorkOrders,
    activeFilters,
    totalDrafts,
    filtersChanged,
    tableRowsLoading,
    modelsInitialized,
    setFiltersChanged,
    handleSort,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    setPageCount,
    setDraftWorkOrders,
    updateWorkOrder,
    handlePageChange,
    setTotalDrafts,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
