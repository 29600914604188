import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { WorkOrder, FilterModel, GenericFilterItem } from "../../types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  loading: boolean;
  suspendedWorkOrdersInit: boolean;
  stateUpdating: boolean;
  filterModels: FilterModel[];
  filters: GenericFilterItem[];
  liveWorkOrders: WorkOrder[];
  suspendedWorkOrders: WorkOrder[];
  searchValue: string;
  liveWorkOrdersInit: boolean;
  viewSuspended: boolean;
  itpActive: boolean;
  setItpActive: Dispatch<SetStateAction<boolean>>;
  setSuspendedWorkOrders: Dispatch<SetStateAction<WorkOrder[]>>;
  setViewSuspended: Dispatch<SetStateAction<boolean>>;
  setFilterModels: Dispatch<SetStateAction<FilterModel[] | undefined>>;
  setStateUpdating: Dispatch<SetStateAction<boolean>>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setLiveWorkOrders: Dispatch<SetStateAction<WorkOrder[]>>;
  handleSearch: (searchValue: string) => void;
  handleFilter: (value: string) => void;
  updatePaymentType: (
    workOrderId: number,
    paymentTypeId: number,
    variant: "suspended" | "live",
    callback?: () => void
  ) => Promise<void>;
  updateWorkOrderStatus: (
    workOrderId: number,
    status: string,
    variant: "suspended" | "live",
    callback?: () => void
  ) => Promise<void>;
  deleteFilter: (filter: FilterModel) => void;
  filterWorkOrders: (workOrders: WorkOrder[]) => WorkOrder[];
  filterSuspendedWorkOrders: (workOrders: WorkOrder[]) => WorkOrder[];
  toggleItpActive: () => void;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  stateUpdating: false,
  suspendedWorkOrdersInit: false,
  filterModels: [],
  filters: [],
  liveWorkOrders: [],
  suspendedWorkOrders: [],
  searchValue: "",
  liveWorkOrdersInit: false,
  viewSuspended: false,
  itpActive: false,
  setItpActive: () => {},
  setViewSuspended: () => {},
  setStateUpdating: () => {},
  setSearchValue: () => {},
  handleSearch: () => {},
  handleFilter: () => {},
  setLiveWorkOrders: () => {},
  filterWorkOrders: () => [],
  filterSuspendedWorkOrders: () => [],
  setFilterModels: () => {},
  deleteFilter: () => {},
  setSuspendedWorkOrders: () => {},
  toggleItpActive: () => {},
  updatePaymentType: () => new Promise((res) => res()),
  updateWorkOrderStatus: () => new Promise((res) => res()),
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
