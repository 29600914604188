import { memo, useEffect, useState, cloneElement, Fragment } from "react";

/**
 * Imports Material UI Components
 */
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Zoom from "@mui/material/Zoom";

/**
 * Imports styled components
 */
import {
  ContentContainer,
  ChildContainer,
  PopperMui,
  OuterArrow,
  InnerArrow
} from "./Tooltip.styles";

/**
 * Imports types
 */
import {
  ChildProps,
  RenderTooltipContentProps,
  TooltipProps,
  PopperMuiProps
} from "./Tooltip.types";

/**
 * Imports hooks
 */
import { useDevice } from "../../hooks";

/**
 * Defines the default props
 */
const defaultProps: TooltipProps = {
  content: "",
  arrow: true,
  placement: "top",
  trigger: "onHover"
};

/**
 * Displays the component
 */
export const Tooltip: React.FC<TooltipProps> = memo((props) => {
  const {
    content,
    arrow,
    placement,
    trigger,
    zIndex,
    onOpen,
    onClose,
    mountOn,
    disableFlip,
    transform,
    children,
    margin,
    mt,
    mb,
    ml,
    mr
  } = props;

  /**
   * Gets the device
   */
  const device = useDevice();

  /**
   * Initializes the action trigger
   */
  const [actionTrigger, setActionTrigger] = useState<TooltipProps["trigger"]>();

  /**
   * Initializes the open state
   */
  const [open, setOpen] = useState(false);

  /**
   * Initializes the arrow ref
   */
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  /**
   * Initializes the child node ref
   */
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);

  /**
   * Handles setting the action trigger
   * On mobile, only click action triggers are allowed.
   */
  useEffect(() => {
    if (device.isMobile) {
      setActionTrigger("onClick");
    } else {
      setActionTrigger(trigger);
    }
  }, [trigger, device]);

  /**
   * If there are no children, return
   */
  if (!children) return null;
  if (mountOn !== undefined && !mountOn) return children;

  /**
   * Checks if there are any existing onClick props on the children.
   */
  const existingOnClick = children.props.onClick;

  /**
   * Gets the node's content
   * Handles functions that return a valid content as well
   */
  const contentNode = typeof content === "function" ? content() : content;

  /**
   * Generates popper modifiers
   */
  const generatePopperModifiers = () => {
    const modifiers: PopperMuiProps["modifiers"] = [
      {
        name: "flip",
        enabled: !disableFlip,
        options: {
          altBoundary: true,
          rootBoundary: "window"
        }
      },
      {
        name: "preventOverflow",
        enabled: true,
        options: {
          altBoundary: true,
          rootBoundary: "window"
        }
      },
      {
        name: "arrow",
        enabled: arrow,
        options: {
          element: arrowRef
        }
      }
    ];

    return modifiers;
  };

  /**
   * Handles togging the tooltip
   * Also executes the onClick on the child
   */
  const toogleTooltipExtended = (e: MouseEvent) => {
    toogleTooltip();
    existingOnClick && existingOnClick(e);
  };

  /**
   * Handles toggling the toolip
   * onHover will debounce the event for UX reasons
   */
  const toogleTooltip = () => {
    setOpen((prevState) => !prevState);
    if (open && onClose) onClose();
    if (!open && onOpen) onOpen();
  };

  /**
   * Handles opening the tooltip
   * onHover will debounce the event for UX reasons
   */
  const openTooltip = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  /**
   * Handles closing the tooltip
   * onHover will debounce the event for UX reasons
   */
  const closeTooltip = () => {
    setOpen(false);
    onClose && onClose();
  };

  /**
   * Handles closing the tooltip on click away
   */
  const handleOnClickAway = () => {
    if (actionTrigger === "onHover") return;
    closeTooltip();
  };

  /**
   * Initializes the child props
   */
  const childProps: ChildProps = {};

  /**
   * Adds the toggleTooltip to the child props if no other click event exists on the child
   */
  if (actionTrigger === "onClick" && !existingOnClick) {
    childProps["onClick"] = toogleTooltip;
  }

  /**
   * Adds the children's onClick to the props if it exists
   * Adds the tooltipExtended to the props if the trigger for the tooltip is onClick
   */
  if (existingOnClick && actionTrigger !== "onHover") {
    childProps["onClick"] = children.props.onClick;

    if (actionTrigger === "onClick") {
      childProps["onClick"] = toogleTooltipExtended;
    }
  }

  /**
   * Adds the props to the child, to manage onHover state
   */
  if (actionTrigger === "onHover") {
    childProps["onMouseEnter"] = openTooltip;
    childProps["onMouseLeave"] = closeTooltip;
  }

  /**
   * Handles rendering the child component
   */
  const renderChildComponent = () =>
    cloneElement(children, {
      ...children.props,
      ...childProps,
      ref: setChildNode
    });

  /**
   * Handles rendering the tooltip content
   */
  const renderTooltipContent = (props: RenderTooltipContentProps) => {
    const { TransitionProps } = props;

    return (
      <Zoom {...TransitionProps} timeout={200}>
        <Paper elevation={0}>
          <ClickAwayListener onClickAway={handleOnClickAway}>
            <ContentContainer elevation={0}>
              {arrow && (
                <OuterArrow ref={setArrowRef} className="Tooltip-outerArrow">
                  <InnerArrow className="Tooltip-innerArrow" />
                </OuterArrow>
              )}
              {contentNode}
            </ContentContainer>
          </ClickAwayListener>
        </Paper>
      </Zoom>
    );
  };

  return (
    <Fragment>
      <ChildContainer className="Tooltip-childContainer">
        {renderChildComponent()}
      </ChildContainer>
      <PopperMui
        transition
        open={open}
        transform={transform}
        anchorEl={childNode}
        placement={placement}
        zIndex={zIndex}
        margin={margin}
        mt={mt}
        mb={mb}
        ml={ml}
        mr={mr}
        modifiers={generatePopperModifiers()}
      >
        {renderTooltipContent}
      </PopperMui>
    </Fragment>
  );
});

Tooltip.defaultProps = defaultProps;
