/**
 * Imports auth utils
 */
import { loadState } from "../../utils";

/**
 * Imports types
 */
import type {
  TWorkOverviewType,
  TWorkOverviewView,
} from "./workOverview.types";
import type { StatsCardItem } from "../../../types";

/**
 * Imports constants
 */
import {
  STATISTICS_KEY,
  WORKOVERVIEW_VIEW_KEY,
  WORKOVERVIEW_TYPE_KEY,
} from "../../../constants";

export const preloadWorkOverviewViewState = () => {
  const storageState = loadState(WORKOVERVIEW_VIEW_KEY) as TWorkOverviewView;

  if (storageState) return storageState;
  return "table";
};

export const preloadWorkOverviewTypeState = () => {
  const storageState = loadState(WORKOVERVIEW_TYPE_KEY) as TWorkOverviewType;

  if (storageState) return storageState;
  return "live";
};

export const preloadStatisticsState = () => {
  const storageState = loadState(STATISTICS_KEY) as StatsCardItem[];

  if (storageState) return storageState;
  return [];
};
