import { createContext } from "react";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  isMobile: boolean;
  isTabletOrBellow: boolean;
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  isMobile: false,
  isTabletOrBellow: false,
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
