import { createContext, Dispatch, SetStateAction } from "react";

/**
 * Imports types
 */
import { AppointmentGroup } from "../../types";

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  loading: boolean;
  appointmentGroups: AppointmentGroup[];
  handleSearch: (searchValue: string) => void;
  setAppointmentGroups: Dispatch<SetStateAction<AppointmentGroup[]>>;
  filterTableResult: (
    appointmentGroups: AppointmentGroup[]
  ) => AppointmentGroup[];
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  loading: false,
  appointmentGroups: [],
  handleSearch: () => {},
  filterTableResult: () => [],
  setAppointmentGroups: () => {}
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
