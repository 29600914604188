/**
 * Imports hooks
 */
import { useSelector, useUtils } from "..";

/**
 * Imports types
 */
import { SocketEvent } from "../../types";
import { ListenProps } from "./useEvents.types";

/**
 * Defines the main hook
 */
export const useEvents = () => {
  /**
   * Gets auth data
   */
  const { accessToken, user } = useSelector((state) => state.auth);

  /**
   * Gets the account state
   */
  const { accountInformation } = useSelector((state) => state.account);

  /**
   * Gets general utils
   */
  const { fromSnakeToCamel } = useUtils();

  /**
   * Builds the channel name
   */
  const getPrivateChannel = (channel: string, organizationId: number) => {
    if (channel === "time-sheet.created" || channel === "time-sheet.updated") {
      return `${channel}.${accountInformation.id}`;
    }

    return `${channel}.${organizationId}`;
  };

  /**
   * Add Authorization token
   */
  const setSocketAuthToken = (token: string) => {
    if (!token || !window.Echo) return;
    // console.log("Set soket", {token, window, echo: window.Echo})
    // window.Echo.connector.pusher.config.auth.headers[

    window.Echo.connector.options.auth.headers[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  /**
   * Handles listening to socket events
   */
  const listen = (props: ListenProps) => {
    const { withAuth, channel, event, callback } = props;

    if (accessToken && user) {
      setSocketAuthToken(accessToken);

      /**
       * Defines the callback function
       */
      const _callback = (payload: any) => {
        const _payload = fromSnakeToCamel(payload);
        callback(_payload as SocketEvent<any>);
      };

      if (withAuth && user && user.organizationId) {
        /**
         * Defines the private channel
         */
        const privateChannel = getPrivateChannel(channel, user.organizationId);

        /**
         * Stop listening if its exists
         */
        window.Echo.private(privateChannel).stopListening(event, _callback);

        /**
         * Start listening
         */
        return window.Echo.private(privateChannel).listen(event, _callback);
      } else if (user && user.organizationId) {
        /**
         * Stop listening if its exists
         */
        window.Echo.private(channel).stopListening(event, _callback);

        /**
         * Start listening
         */
        return window.Echo.private(channel).listen(event, _callback);
      }
    }
  };

  return {
    listen,
  };
};
