import { isAnyOf } from "@reduxjs/toolkit";

/**
 * Imports queries
 */
import { loginUser, logoutUser, switchUser } from "./auth.api";

/**
 * Imports action creators
 */
import { authActionCreators } from "./auth.slice";

/**
 * Imports types
 */
import { AppStartListening } from "../../store";

/**
 * Imports utils
 */
import { saveState, removeState } from "../../utils";

/**
 * Imports constants
 */
import {
  AUTH_KEY,
  NOTIFICATIONS_KEY,
  ACCOUNT_DELETED_KEY,
  NOTIFICATION_VIEW_KEY,
  NOTIFICATION_SETTINGS_KEY,
  DEFAULT_WORKORDER_TYPE_KEY,
  APPOINTMENTS_VIEW_KEY,
  APPOINTMENTS_RESOURCES_KEY,
  APPOINTMENTS_ACTIVE_ORG_KEY,
  APPOINTMENTS_ACTIVE_GROUPS_KEY,
  APPOINTMENTS_WITH_GROUPING_KEY,
  APPOINTMENTS_INCLUDE_GROUPLESS_KEY,
} from "../../../constants";

const loginMatcher = isAnyOf(
  loginUser.matchFulfilled,
  switchUser.matchFulfilled,
);

/**
 * Listens to the login action
 */
export const addLoginListener = (startListening: AppStartListening) => {
  startListening({
    matcher: loginMatcher,
    effect: (action, listenerApi) => {
      /**
       * Gets the auth object
       */
      const auth = action.payload.data;

      /**
       * Updates local storage
       */
      saveState(AUTH_KEY, auth);
    },
  });
};

/**
 * Listens to the logout action
 */
export const addLogutListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(logoutUser.matchFulfilled, logoutUser.matchRejected),
    effect: (action, listenerApi) => {
      listenerApi.dispatch(authActionCreators.logout());

      /**
       * Updates local storage
       * Temporary
       */
      removeState("liveWorkOrderFilters");
      removeState("liveWorkOrderFilterModels");
      removeState("appointmentsActiveOrg");
      removeState("appointmentsActiveGroups");
      removeState(ACCOUNT_DELETED_KEY);
      removeState(DEFAULT_WORKORDER_TYPE_KEY);
      removeState(AUTH_KEY);

      /** Notifications persisted state */
      removeState(NOTIFICATIONS_KEY);
      removeState(NOTIFICATION_SETTINGS_KEY);
      removeState(NOTIFICATION_VIEW_KEY);

      /** Appointments persisted state */
      removeState(APPOINTMENTS_VIEW_KEY);
      removeState(APPOINTMENTS_RESOURCES_KEY);
      removeState(APPOINTMENTS_ACTIVE_ORG_KEY);
      removeState(APPOINTMENTS_ACTIVE_GROUPS_KEY);
      removeState(APPOINTMENTS_WITH_GROUPING_KEY);
      removeState(APPOINTMENTS_INCLUDE_GROUPLESS_KEY);
    },
  });
};
