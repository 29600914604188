import { useState, useEffect, useCallback } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import {
  useApi,
  useSearch,
  useActions,
  useSelector,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FilterModel, AccountUser, TableData } from "../../types";
import {
  SearchUsersBody,
  SearchUsersOnSuccess,
  RequestOnError,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const UsersProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the users
   */
  const [users, setUsers] = useState<AccountUser[]>([]);

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the table rows loading
   */
  const [tableRowsLoading, setTableRowsLoading] = useState(false);

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the total users
   */
  const [totalUsers, setTotalUsers] = useState(0);

  /**
   * Initializes the order by state
   */
  const [orderBy, setOrderBy] = useState("username");

  /**
   * Initializes the order dir state
   */
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("asc");

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the filter model utility hook
   */
  const {
    formatActiveFilters,
    addOrReplaceFilter,
    createFilter,
    removeFilter,
  } = useFilterModelsUtils();

  /**
   * Gets the user utility functions
   */
  const { getUserOrganization } = useUserUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Handles creating a report based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchUsers(filterModels, 1);
    setActiveFilters(filterModels);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchUsers(filters, 1);
    }, 500);
  };

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = useCallback(() => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    /**
     * Creates the filter models
     */
    if (organization) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = (error) => {
    setLoading(false);
    setTableRowsLoading(false);
    dispatchMessage({
      message: error.errorMessage ? error.errorMessage : "Unknown Error",
      severity: "error",
      autoClose: 10000,
    });
  };

  /**
   * Handles searching for users
   */
  const searchUsers = async (filters: FilterModel[], page?: number) => {
    /**
     * Defines the request body
     */
    const reqBody: SearchUsersBody = {
      models: filters.filter((model) => !model.displayOnly),
      order_by: orderBy,
      order_dir: orderDir,
      page_count: page || pageCount,
      page_size: 15,
    };

    /**
     * Defines the api call success callback
     */
    const onRequestSuccess: SearchUsersOnSuccess = (response) => {
      if (response && response.data) {
        const { data } = response;
        const { items, pageCount, orderBy, orderDir, total } = data;

        setUsers(items);
        setPageCount(pageCount);
        setOrderBy(orderBy);
        setOrderDir(orderDir);
        setTotalUsers(total);
        setLoading(false);
        setTableRowsLoading(false);
      }
    };

    await apiCalls.searchUsers(reqBody, onRequestSuccess, onRequestError);
  };

  /**
   * Handles updating the user in the table
   */
  const updateUser = (user: AccountUser) => {
    const index = findIndex(users, { id: user.id });
    const clonedUsers = cloneDeep(users);
    clonedUsers[index] = user;

    setUsers(clonedUsers);
  };

  /**
   * Handles updating the current page state
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Handles sorting, updates states
   */
  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles searching for users once filter models are set
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchUsers(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchUsers(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    orderBy,
    loading,
    orderDir,
    pageCount,
    users,
    activeFilters,
    totalUsers,
    tableRowsLoading,
    modelsInitialized,
    handleSort,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    setPageCount,
    setUsers,
    updateUser,
    handlePageChange,
    setTotalUsers,
    formatActiveFilters,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
