import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

/**
 * Types
 */
import { ReduxSlice } from "../../features";
import type { Appointment, FilterModel, SocketEvent } from "../../../types";
import type {
  AppointmentsState,
  AppointmentsViewType,
  CalendarActiveViewType,
} from "./appointments.types";

/**
 * API
 */
import { getAppointments } from "./appointments.api";
import { fetchAccountInformation } from "../../features";

/**
 * Utils
 */
import {
  preloadViewState,
  getTodayAppointments,
  preloadResourcesState,
  preloadWithGroupingState,
  preloadActiveGroupsState,
  preloadIncludeGrouplessState,
  preloadActiveOrganizationState,
} from "./appointments.utils";

import { DEFAULT_CALENDAR_ACTIVE_VIEW } from "../../../constants";
import { AppointmentModel, Resource } from "@devexpress/dx-react-scheduler";

const initialState: AppointmentsState = {
  view: preloadViewState(),
  activeOrganization: preloadActiveOrganizationState(),
  resources: preloadResourcesState(),
  withGrouping: preloadWithGroupingState(),
  activeGroups: preloadActiveGroupsState(),
  includeGroupless: preloadIncludeGrouplessState(),
  isLoading: false,
  currentDate: new Date().valueOf(),
  appointments: [],
  todayAppointments: [],
  filterModels: [],
  calendarData: [],
  calendarActiveView: DEFAULT_CALENDAR_ACTIVE_VIEW,
};

export const appointmentsSlice = createSlice({
  name: ReduxSlice.Appointments,
  initialState,
  reducers: {
    updateAppointmentsLive: (
      state,
      action: PayloadAction<{
        socketPayload: SocketEvent<any>;
        modelName: string;
      }>,
    ) => {
      const { modelName, socketPayload } = action.payload;

      if (socketPayload.modelName === modelName) {
        const { event, model } = socketPayload;

        if (event === "CREATED") {
          state.appointments.push(model);
        }

        if (event === "UPDATED") {
          state.appointments = state.appointments.map((item) =>
            item.id === model.id ? { ...model } : item,
          );
        }

        if (event === "DELETED") {
          state.appointments = state.appointments.filter(
            (item) => item.id !== model.id,
          );
        }
      }
    },
    updateAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.appointments = action.payload;
    },
    updateTodayAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.todayAppointments = action.payload;
    },
    updateAppointmentsView: (
      state,
      action: PayloadAction<AppointmentsViewType>,
    ) => {
      state.view = action.payload;
    },
    updateAppointmentsResources: (state, action: PayloadAction<Resource[]>) => {
      state.resources = action.payload;
    },
    updateAppointmentsCalendarData: (
      state,
      action: PayloadAction<AppointmentModel[]>,
    ) => {
      state.calendarData = action.payload;
    },
    updateAppointmentsWithGrouping: (state, action: PayloadAction<boolean>) => {
      state.withGrouping = action.payload;
    },
    updateAppointmentsToIncludeGroupless: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.includeGroupless = action.payload;
    },
    updateAppointmentsCurrentDate: (state, action: PayloadAction<Date>) => {
      state.currentDate = action.payload.valueOf();
    },
    updateAppointmentsActiveGroups: (
      state,
      action: PayloadAction<number[]>,
    ) => {
      state.activeGroups = action.payload;
    },
    updateAppointmentsActiveOrganization: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.activeOrganization = action.payload;
    },
    updateAppointmentsCalendarActiveView: (
      state,
      action: PayloadAction<CalendarActiveViewType>,
    ) => {
      state.calendarActiveView = action.payload;
    },
    updateAppointmentsFilterModels: (
      state,
      action: PayloadAction<FilterModel[]>,
    ) => {
      state.filterModels = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getAppointments.matchPending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(getAppointments.matchFulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.appointments = payload.data;
      state.todayAppointments = getTodayAppointments(payload.data);
    });
    builder.addMatcher(
      fetchAccountInformation.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;

        if (data.user && data.user.organizationId) {
          state.activeOrganization = data.user.organizationId;
        }
      },
    );
  },
});

export const appointmentsActionCreators = appointmentsSlice.actions;
export const appointmentsReducer = appointmentsSlice.reducer;
